import React from "react";
import {Button, Dialog, Grid, TextField, Theme} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useTheme} from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./EditBoardDialog.scss"
import {BaseComponent} from "../Reusable/BaseComponent";
import {BoardModel} from "../../Models/BoardModel";
import {Validation} from "../../Tools/Validation";
import ValidationMgr from "../../Tools/ValidationMgr";
import {BoardCtlr} from "../Board/BoardCtlr";
import {DevOpsConnectorModel} from "../../Models/DevOpsConnectorModel";
import {BoardSelectDialogInstance} from "../BoardSelectDialog/BoardSelectDialog";
import {AppCtlr} from "../../AppCtlr";
import {BigLoading} from "../Reusable/BigLoading";
import {DevOpsConnector} from "./DevOpsConnector";
import {DevOpsExtensionConnector} from "./DevOpsExtensionConnector";

interface IState {
    IsOpen: boolean;
    Name: string;
    Description: string;
    IsLoading: boolean;

    DevOpsConnectorModel: DevOpsConnectorModel;

    InitialBoard: BoardModel;
}

interface IProps{
    IsFullScreen: boolean;
}

export class EditBoardDialogInternal extends BaseComponent<IProps, IState> {
    private _validationMgr: ValidationMgr = new ValidationMgr();
    public static _instance: EditBoardDialogInternal;
    private _cleanState: IState = {
        IsOpen: false,
        Name: '',
        Description: '',
        InitialBoard: null,
        IsLoading: false,
        DevOpsConnectorModel: new DevOpsConnectorModel()
    };

    constructor(props: IProps) {
        super(props);
        this.state = { ...this._cleanState };
        EditBoardDialogInternal._instance = this;
    }

    async componentDidMount() {
    }

    private Close = () => {
        this.setState( { ...this._cleanState} );
    }

    private OnCancelClick = () => {
        if( BoardCtlr.Instance.Board )
            this.Close();
        else if( BoardCtlr.Instance.Boards.length > 0) {
            this.Close();
            BoardSelectDialogInstance.Open();
        }
    }

    private OnCreateClick = () => {
        if( this._validationMgr.HasError )
        {
            this._validationMgr.ErrorsDisplayed = true;
            this.forceUpdate();
            return;
        }

        let board = new BoardModel({Name: this.state.Name, Description: this.state.Description});
        if( this.state.DevOpsConnectorModel.IsConnected ) {
            board.DevOpsConnector =  this.state.DevOpsConnectorModel;
        }
        BoardCtlr.Instance.SetBoard(board);
        this.Close();
    }

    render() {
        console.log("EditBoardDialogInternal.render");

        if( this.state.IsLoading )
            return <BigLoading message={"Loading DevOps queries ..."}/>;

        const isDevOpsExtension = AppCtlr.Instance.IsDevOpsExtension;
        const isOpen = this.state.IsOpen;
        const canClose = BoardCtlr.Instance.Board || (BoardCtlr.Instance.Boards && BoardCtlr.Instance.Boards.length > 0);
        const isNew = !this.state.InitialBoard?.Id;
        this._validationMgr.StartValidation();

        return (
            isOpen &&
            <Dialog
                fullScreen={this.props.IsFullScreen}
                open={true}
                classes={{paper: "edit-board-dialog nscrollable"}}
            >
                <DialogTitle id="responsive-dialog-title">
                    { isNew ? "Create new the-Board" : "Edit the-Board"}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                required
                                { ...this.WithLabel("Name") }
                                { ...this.BindState("Name") }
                                {...this._validationMgr.Validate(this.state.Name, Validation.Required() )}
                            />
                            <TextField
                                {...this.WithLabel("Description")}
                                { ...this.BindState("Description") }
                            />
                            <div className={"divider"}/>
                            {
                                isDevOpsExtension
                                    ? <DevOpsExtensionConnector DevOpsConnector={this.state.DevOpsConnectorModel}/>
                                    : <DevOpsConnector DevOpsConnector={this.state.DevOpsConnectorModel}/>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={"actions"}>
                    <Button onClick={this.OnCreateClick} className={"create-btn"} >
                        {isNew ? "Create" : "Save"}
                    </Button>
                    <Button autoFocus onClick={this.OnCancelClick} disabled={!canClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default function EditBoardDialog() {
    const theme : Theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <EditBoardDialogInternal IsFullScreen={fullScreen}/>
    );
}

export class EditBoardDialogInstance {
    public static Open(board: BoardModel) {
        EditBoardDialogInternal._instance.setState({
            IsOpen: true,
            Name: board.Name,
            Description: board.Description,
            DevOpsConnectorModel: new DevOpsConnectorModel(board.DevOpsConnector),
            InitialBoard: board
        });
    }
}
