import {MappingType} from "./ColumnModel";

export class ColumnsConfig {
    constructor(data?: Partial<ColumnsConfig>) {
        Object.assign(this, data);
    }

    // The name of the filed in DevOps columns mapped to
    public MapTo: MappingType

    // Automatically modify DevOps data when moving from column to column
    public SyncToDevOps: boolean;
}
