import {BaseCtlr} from "../../Tools/BaseCtlr";
import {ColumnModel, MappingType} from "../../Models/ColumnModel";
import {CardModel, CardType} from "../../Models/CardModel";
import {Event, EventArgs} from "../../Tools/Event";
import DateTime from "../../Tools/DateTime";
import {RandomInteger} from "../../Tools/NumberEx";
import {ColumnList} from "../../Models/ColumnList";
import {Rectangle} from "../../Tools/Drawing";
import {deserialize, serialize} from "class-transformer";
import {BoardModel} from "../../Models/BoardModel";
import {BaseFilter} from "./Filters";
import {ICardsStorage} from "../../Controllers/ICardsStorage";
import {DevOpsCardsStorage} from "../../Controllers/DevOpsCardsStorage";
import {LocalCardsStorage} from "../../Controllers/LocalCardsStorage";
import {IDevOpsClient} from "../../Controllers/DevOps/IDevOpsClient";
import {DevOpsExtensionClient} from "../../Controllers/DevOps/DevOpsExtensionClient";
import {AppCtlr} from "../../AppCtlr";
import {DevOpsClient} from "../../Controllers/DevOps/DevOpsClient";

export class BoardCtlr extends BaseCtlr {
    private static _instance: BoardCtlr = new BoardCtlr();
    private _focusedColumn: ColumnModel;
    private _maxZindex: number = 1;
    private _cards: CardModel[] = [];
    private _draggingCard: CardModel;
    private _boards: BoardModel[];
    private _activeBoard: BoardModel;
    private _cardsStorage: ICardsStorage;
    private _filter: BaseFilter;
    private _filteredCards: [boolean, CardModel][] = [];
    private _devOpsClient: IDevOpsClient;

    static get Instance(): BoardCtlr {
        return this._instance;
    }

    public get FocusedColumn() : ColumnModel {
        return this._focusedColumn;
    }

    public get Board(): BoardModel {
        return this._activeBoard;
    }

    get DevOpsClient(): IDevOpsClient {
        return this._devOpsClient;
    }

    public get Boards(): BoardModel[] {
        return this._boards;
    }

    public set FocusedColumn(value: ColumnModel) {
        this.SetWithNotification("_focusedColumn", "FocusedColumn", value);
    }

    public get Filter() : BaseFilter {
        return this._filter;
    }

    public set Filter(value: BaseFilter)  {
        this._filteredCards = !value ? [] : value.Apply(this._cards);
        this.SetWithNotification("_filter", "Filter", value);
    }

    public get FilteredCards() : [boolean, CardModel][] {
        return this._filteredCards;
    }

    public async Load() {
        this.LoadBoards();

        const activeBoard = this._boards.FirstOrDefault(x => x.IsActive);

        if( activeBoard ) {
            await this.SetBoard(activeBoard);
        }
    }

    public async SetBoard(board: BoardModel) {
        this._boards.forEach(x =>  {
            if( x.IsActive ) {
                x.IsActive = false;
                this.SaveBoard(x);
            }
        });

        board.IsActive = true;
        this.SaveBoard(board);

        this._activeBoard = board;
        this._devOpsClient = AppCtlr.Instance.IsDevOpsExtension ? new DevOpsExtensionClient(board.DevOpsConnector) : new DevOpsClient(board.DevOpsConnector);
        this._cardsStorage = this.CreateCardsStorage(board);

        this._cards = [];
        this._maxZindex = 0;

        if( board ) {
            ColumnList.Instance.Load(board);

            this._cards = await this._cardsStorage.LoadCards();

            if( board.DevOpsConnector ) {
                this.AdjustCardsPositionsBasedOnDevops();
            }

            this._maxZindex = this._cards.Max(x => x.Zindex)?.Zindex || 1;
        }

        this.CardsChangedEvent.Notify(new EventArgs());
        this.BoardChangedEvent.Notify(new EventArgs());
    }

    private CreateCardsStorage(board: BoardModel) {
        if( board.DevOpsConnector )
            return new DevOpsCardsStorage(board);

        return new LocalCardsStorage(board);
    }

    private async SaveBoard(board: BoardModel) {
        if( !board.Id )
            board.Id = (this._boards.Max(x => x.Id)?.Id || 100) + 1;

        localStorage[this.GetBoardKeyPrefix() + board.Id] = serialize( board );

        if( !this._boards.Any(x => x == board) )
            this._boards.Add(board);
    }

    public async SaveCardPosition(card: CardModel, posX: number, posY: number, column: ColumnModel) {
        await this._cardsStorage.SaveCardPosition(card, posX, posY, column);
    }

    public async SaveCardSize(card: CardModel, width: number, height: number) {
        await this._cardsStorage.SaveCardSize(card, width, height);
    }

    public async AddCard(title: string, url: string, body: string, color: string, tags: string[]) {
        const card = new CardModel( {
            Id: DateTime.now.msSinceEpoch + "_" + RandomInteger(0, 999),
            Title: title,
            Body: body,
            Color: color,
            TitleUrl: url,
            Zindex: BoardCtlr.Instance.MaxZindex + 1,
            Tags: tags
        });

        BoardCtlr.Instance.MaxZindex = BoardCtlr.Instance.MaxZindex + 1;

        await this._cardsStorage.AddCard(card);

        this._cards.Add(card);
        this.CardsChangedEvent.Notify(new EventArgs());
    }

    public async SaveCard(id: string, title: string, url: string, body: string, color: string, tags: string[]) {
        const card = this._cards.Single(x => x.Id === id);

        card.Title = title;
        card.TitleUrl = url;
        card.Body = body;
        card.Color = color;
        card.Tags = tags;

        await this._cardsStorage.SaveCard(card);

        this.CardsChangedEvent.Notify(new EventArgs());
    }

    public async DeleteCard(card: CardModel) {
        await this._cardsStorage.DeleteCard(card);
        this.CardsChangedEvent.Notify(new EventArgs());
    }

    public async RestoreCard(card: CardModel) {
        await this._cardsStorage.RestoreCard(card);
        this.CardsChangedEvent.Notify(new EventArgs());
    }

    public get MaxZindex() : number {
        return this._maxZindex;
    }

    public set MaxZindex(value: number) {
        this._maxZindex = value;
    }

    public get Cards() : CardModel[] {
        return this._cards;
    }

    public get DraggingCard() : CardModel {
        return this._draggingCard;
    }

    public set DraggingCard(item: CardModel) {
        this.SetWithNotification("_draggingCard", "DraggingCard", item);
    }

    public readonly CardsChangedEvent: Event<EventArgs> = new Event<EventArgs>();

    public readonly BoardChangedEvent: Event<EventArgs> = new Event<EventArgs>();

    // Moves cards if needed to the approprite (based on mapping) column
    private AdjustCardsPositionsBasedOnDevops() {
        for (let i=0; i<this._cards.length; i++) {
            const card = this._cards[i];

            if( card.CardType === CardType.DevOps ) {
                let col : ColumnModel = null;

                if( ColumnList.Instance.ColumnsConfig.MapTo === MappingType.State )
                    col = ColumnList.Instance.GetColumnByMapping(card.DevOpsState);
                if( ColumnList.Instance.ColumnsConfig.MapTo === MappingType.Area )
                    col = ColumnList.Instance.GetColumnByMapping(card.DevOpsArea);

                if( col ) {
                    const prevColsWidth = ColumnList.Instance.Columns.Where(x => x.Order < col.Order).Sum(x => x.Width) || 0;
                    const colRect = new Rectangle(
                        this._activeBoard.Rect.Size.Width * prevColsWidth / 100,
                        ColumnModel.TitleHeight,
                        this._activeBoard.Rect.Size.Width * (prevColsWidth + col.Width) / 100,
                        this._activeBoard.Rect.Size.Height);
                    const cardRect = new Rectangle(card.PosX, card.PosY, card.PosX + card.Width, card.PosY);

                    if( !cardRect.IsIntersect(colRect) ) {
                        // move card to the appropriate col
                        card.PosX = colRect.LeftTop.X + 20;
                        card.PosY = colRect.LeftTop.Y + 20;

                        card.IsHighlight = true;
                        card.Zindex = this.MaxZindex++;
                    }
                }
            }
        }
    }

    private GetBoardKeyPrefix() : string {
        return "#Board_";
    }

    private LoadBoards() {
        this._boards = [];

        for (var i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.startsWith(this.GetBoardKeyPrefix())) {
                const board = deserialize(BoardModel, localStorage[key]);
                this._boards.push(board);
            }
        }

        this._boards = this._boards.OrderBy(x => x.Id);
        //
        // if( !this._boards.length ) {
        //     this._boards.push(new BoardModel( {Id: 1, Name: "Default", Description: "My first board"}));
        // }
    }

    public GetCardTags() {
        return this._cards.SelectMany(x => x.Tags).Distinct(x => x);
    }
}
