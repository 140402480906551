import React, {Component} from "react";
import ValidationMgr from "../../Tools/ValidationMgr";
import Background from "./pallet_wall.jpg";
import {Card} from "./Card";
import "./Board.scss";
import {Column} from "./Column";
import {BoardCtlr} from "./BoardCtlr";
import {ColumnList} from "../../Models/ColumnList";
import {matchesSelectorAndParentsTo} from "../../Tools/SelectorEx";
import EditCardDialog from "../EditCardDialog/EditCardDialog";
import ColumnsConfigDialog from "../ColumnsConfigDialog/ColumnsConfigDialog";
import {PropertyChangedEventArgs} from "../../Tools/PropertyChangedEvent";
import BoardSelectDialog from "../BoardSelectDialog/BoardSelectDialog";
import {BaseComponent} from "../Reusable/BaseComponent";

interface IState {
    IsLoading: boolean
}

interface IProps {
}

var containerStyle = {
    width: "100%",
    height: 1920,
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat'
};

export class Board extends BaseComponent<IProps, IState> {
    private _validationMgr: ValidationMgr = new ValidationMgr();
    private _containerRef: React.RefObject<any>;
    private _isScrolling: boolean = false;
    private _clientX: number = 0;
    private _clientY: number = 0;

    constructor(props: IProps) {
        super(props);
        this._containerRef = React.createRef();

        this.state ={
            IsLoading: true
        };

        BoardCtlr.Instance.PropertyChanged.ListenProps(["DraggingCard", "Filter"], this.OnBoardCtlrPropChanged);
        ColumnList.Instance.PropertyChanged.ListenProps(["Columns"], this);
        BoardCtlr.Instance.CardsChangedEvent.Listen(this);
    }

    private OnBoardCtlrPropChanged = (args: PropertyChangedEventArgs) => {
        if( args.PropName === "DraggingCard" )
        {
            if( !args.NewValue) {
                this.FocusColumnUnderCursor(null);
                return;
            }
        }

        this.forceUpdate();
    }

    componentWillUnmount() {
        BoardCtlr.Instance.PropertyChanged.Unlisten(this.OnBoardCtlrPropChanged);
        BoardCtlr.Instance.CardsChangedEvent.Unlisten(this);
        ColumnList.Instance.PropertyChanged.Unlisten(this);
    }

    StopScrolling =  () => {
        this._containerRef.current.classList.remove("scrolling");
        this._isScrolling = false;
    };


    onMouseDown = (event: any) => {
        const cancel = ".nscrollable";

        const thisNode = this._containerRef.current;
        if( matchesSelectorAndParentsTo(event.target, cancel, thisNode) ) return;

        this._clientX = event.clientX + window.scrollX;
        this._clientY = event.clientY + window.scrollY;
        this._isScrolling = true;
        this._containerRef.current.classList.add("scrolling");
    };

    // async componentDidMount() {
    //     // this.setState( { IsLoading: true} );
    //     //
    //     // let data;
    //     //
    //     // try {
    //     //     //data = await Api.GetData();
    //     // }
    //     // finally {
    //     //     this.setState( { Data: data, IsLoading: false } );
    //     // }
    // }


    render() {
        console.log("Board.render");

        this._validationMgr.StartValidation();

        const renderCards =
            BoardCtlr.Instance.Filter
                ? BoardCtlr.Instance.FilteredCards.Where(x => !x[1].IsDeleted).map(x => { return <Card key={x[1].Id} Model={x[1]} FilteredOut={!x[0]}/>; })
                : BoardCtlr.Instance.Cards.Where(x => !x.IsDeleted).map(x => {return <Card key={x.Id} Model={x}/>; });

        return (
            <div className="board-container" style={containerStyle} onMouseMove={ this.OnMouseMove } ref={this._containerRef}
                 onMouseDown={this.onMouseDown}
                 onMouseUp={this.StopScrolling}
            >
                {
                    renderCards
                }
                {this.RenderColumns()}
            </div>
        );
    }

    private RenderColumns = () => {
        return (
          <div className={"col-container"}>
              { ColumnList.Instance.Columns.map((x, index) => {
                  return (
                      <Column key={index} Column={x} />
                  );
              })}
          </div>
        );
    }

    private OnMouseMove = (event: any) => {
        if( this._isScrolling ) {
            // Left mouse button
            if( event.buttons !== 1 ) {
                this.StopScrolling();
            }
            else {
                window.scrollTo((this._clientX - event.clientX), (this._clientY - event.clientY));
            }
        }

        // Highlight column when dragging a card
        if( !BoardCtlr.Instance.DraggingCard ) return;

        this.FocusColumnUnderCursor(event.pageX);
    }

    private FocusColumnUnderCursor = (cursorX: number) => {
        if( !!!cursorX ) {
            BoardCtlr.Instance.FocusedColumn = null;
            return;
        }
        else {
            const rect = this._containerRef.current.getBoundingClientRect();
            const percent = 100 * cursorX / rect.width;

            let offsetPersent = 0;
            for (let i = 0; i < ColumnList.Instance.Columns.length; i++) {
                const col = ColumnList.Instance.Columns[i];
                offsetPersent += col.Width;

                if (offsetPersent >= percent) {
                    BoardCtlr.Instance.FocusedColumn = col;
                    break;
                }
            }
        }
    }

}

