import * as log4javascript from "log4javascript";

const level = log4javascript.Level.WARN; //Config.LogLevel;
const Log = log4javascript.getLogger();
log4javascript.logLog.setQuietMode(true);
Log.setLevel(level);
const consoleAppender = new log4javascript.BrowserConsoleAppender();
const layout = new log4javascript.PatternLayout("%d{HH:mm:ss.SSS} %-5p %m%n");
consoleAppender.setLayout(layout);
consoleAppender.setThreshold(level);
Log.addAppender(consoleAppender);
export function InitAjaxAppender(apiUrl: string, accessToken: string) {
    const ajaxAppender = new log4javascript.AjaxAppender( apiUrl + 'tools/log');
    const jsonLayout = new log4javascript.JsonLayout();
    ajaxAppender.setLayout(jsonLayout);
    ajaxAppender.addHeader("Content-Type", "application/json");
    ajaxAppender.addHeader("Authorization", 'Bearer ' + accessToken);
    ajaxAppender.setThreshold(log4javascript.Level.ERROR);
    Log.addAppender(ajaxAppender);
}
export default Log;