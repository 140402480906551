export class FetchOptions {
    constructor(data?: Partial<FetchOptions>) {
        Object.assign(this, data);
    }

    BasicAuthToken: string;
    Method: string;
    ContentType: string;
}

export class BaseApi {
    private _errorHandler: (message: string, error: Error, isSapError: boolean) => void;

    public set ErrorHandler(handler: (message: string, error: Error, isSapError: boolean) => void) {
        this._errorHandler = handler;
    }

    public CurrentLanguage: string;

    public get ImpersonatedUser(): string {
        const savedValue = window.localStorage.getItem("impersonatedUser");
        return JSON.parse(savedValue);
    }

    protected AddQueryParam(queryStr: string, param: string): string {
        if (!queryStr) queryStr = "?" + param;
        else queryStr += "&" + param;

        return queryStr;
    }

    protected async getJson<OT>(url: string, options?: FetchOptions): Promise<OT> {
        const responseText = await this.getText(url, options);

        try {
            const json = responseText ? JSON.parse(responseText) : null;
            return json;
        } catch (e) {
            await this.HandleError(url, undefined, e as Error);
            throw e;
        }
    }

    protected async getText(url: string, options?: FetchOptions): Promise<string | null> {
        const fullUrl = url;

        options = options || await this.GetFetchOptions();

        let response;

        let headers = {localizationLang: this.CurrentLanguage};

        if( options?.BasicAuthToken )
            headers["Authorization"] = "Basic " + btoa(":" + options.BasicAuthToken);

        if(this.ImpersonatedUser){
            headers["impersonateContact"] = this.ImpersonatedUser;
        }

        headers["mode"] = "no-cors";

        try {
            // In case of the error in fetch it shows error on the console, but doesn't throw the error.
            // Returns response.ok == false instead.
            response = await fetch(fullUrl, {
                cache: "no-cache",
                //credentials: "include",
                headers: {
                    ...headers
                },
            });
        } catch (e) {
            await this.HandleError(fullUrl, undefined, e as Error);
            throw e;
        }

        if (!response.ok) {
            await this.HandleError(fullUrl, response);
            return null;
        }

        const responseText = await response.text();

        return responseText;
    }

    ///
    /// Does post and returns result as a text
    ///
    protected async postText(url: string, data: any, options?: FetchOptions): Promise<string> {
        const fullUrl = url;
        options = options || await this.GetFetchOptions();

        let response;

        let headers = {localizationLang: this.CurrentLanguage};

        if( options?.BasicAuthToken )
            headers["Authorization"] = "Basic " + btoa(":" + options.BasicAuthToken);

        if(this.ImpersonatedUser){
            headers["impersonateContact"] = this.ImpersonatedUser;
        }

        headers["mode"] = "no-cors";

        try {
            // In case of the error in fetch it shows error on the console, but doesn't throw the error.
            // Returns response.ok == false instead.
            const json = JSON.stringify(data);

            response = await fetch(fullUrl, {
                method: options.Method || "POST",
                headers: {
                    "Content-Type": options.ContentType || "application/json; charset=utf-8",
                    ...headers,
                },
                body: json,
            });
        } catch (e) {
            await this.HandleError(fullUrl, null, e as Error);
            throw e;
        }

        if (!response.ok) {
            await this.HandleError(fullUrl, response, null);
            return null;
        }

        const responseText = await response.text();

        return responseText;
    }

    ///
    /// Does post and returns result as an object, parsed from json
    ///
    protected async postJson<OT>(url: string, data: any, options?: FetchOptions): Promise<OT> {
        const responseText = await this.postText(url, data, options);

        const json = responseText ? JSON.parse(responseText) : null;
        return json;
    }

    protected async HandleError(url: string, response?: Response, error?: Error) {
        let errorMessage = error && error.toString();
        const httpStatus = response && response.status;
        const httpStatusText = response && response.statusText;

        if (response) {
            const responseMessage = await response.text();

            try {
                var errorDetails = JSON.parse(responseMessage);
                errorMessage = errorDetails.Message || responseMessage;
            } catch {}
        }

        let message = errorMessage || `Error while fetching data from Api ${url}: ${httpStatus} (${httpStatusText})`;

        const newError = error ? error : new Error(message);
        if (httpStatus) newError["httpStatus"] = httpStatus;
        if (url) newError["url"] = url;

        const sapErrorPrefix = "Sap returned an error.";
        const isSapError = message.startsWith(sapErrorPrefix);
        if(isSapError){
            message = message.substring(sapErrorPrefix.length);
        }
        if (this._errorHandler) this._errorHandler(message, error, isSapError);

        throw newError;
    }

    protected async GetFetchOptions() : Promise<FetchOptions> {
        return new FetchOptions();
    }
}
