export function findInArray(array: Array<any> | TouchList, callback: Function): any {
    for (let i = 0, length = array.length; i < length; i++) {
        if (callback.apply(callback, [array[i], i, array])) return array[i];
    }
}

export function isFunction(func: any): boolean {
    // $FlowIgnore[method-unbinding]
    return typeof func === 'function' || Object.prototype.toString.call(func) === '[object Function]';
}

export function isNum(num: any): boolean {
    return typeof num === 'number' && !isNaN(num);
}

export function int(a: string): number {
    return parseInt(a, 10);
}

export function dontSetMe(props: Object, propName: string, componentName: string): Error {
    if (props[propName]) {
        return new Error(`Invalid prop ${propName} passed to ${componentName} - do not set this, set it on the child.`);
    }

    return null;
}

let matchesSelectorFunc = '';
export function matchesSelector(el: Node, selector: string): boolean {
    if (!matchesSelectorFunc) {
        matchesSelectorFunc = findInArray([
            'matches',
            'webkitMatchesSelector',
            'mozMatchesSelector',
            'msMatchesSelector',
            'oMatchesSelector'
        ], function(method: any){
            return isFunction(el[method]);
        });
    }

    // Might not be found entirely (not an Element?) - in that case, bail
    // $FlowIgnore: Doesn't think elements are indexable
    if (!isFunction(el[matchesSelectorFunc])) return false;

    // $FlowIgnore: Doesn't think elements are indexable
    return el[matchesSelectorFunc](selector);
}

// Works up the tree to the draggable itself attempting to match selector.
export function matchesSelectorAndParentsTo(el: Node, selector: string, baseNode: Node): boolean {
    let node = el;
    do {
        if (matchesSelector(node, selector)) return true;
        if (node === baseNode) return false;
        node = node.parentNode;
    } while (node);

    return false;
}

