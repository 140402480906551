import "reflect-metadata"
import "./Tools/ArrayEx";
import React, {useEffect} from 'react';
import './App.scss';

import Api from "./Api";
import {IntlContext} from "./Components/Reusable/IntlProviderEx";
import {AppRouter} from "./Tools/Routing/AppRouter";
import {AppCtlr} from "./AppCtlr";
import Log from "./Tools/Logger";
import {CssBaseline} from "@mui/material";
import figlet from 'figlet';
// @ts-ignore
import standard from 'figlet/importable-fonts/Standard.js'
import {LocalizationProvider} from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MessageBox from "./Components/Reusable/MessageBox";

export function HideRootLoading() {
    // Hide root loading indicator
    document.getElementById("root-loading").style.display = "none";
}

function SayHello() {
    try {
        figlet.parseFont('Standard', standard);

        figlet.text('IDentity', {
            font: 'Standard',
        }, function (err: Error, data: string) {
            if (err) {
                console.log('Something went wrong...');
                console.dir(err);
                return;
            }
            console.log("%c" + data, 'color: #bada55');
        });
    } catch(err) {
        console.log('figlet failed!');
        console.dir(err);
    }
}

function App() {
    useEffect (() => {
        SayHello();

        HideRootLoading();
        Api.ErrorHandler = (message: string) => {
            MessageBox.Error(message);
        }

        AppCtlr.Instance.Browser.NavigateHome();
    });

    Log.debug(`App.render`)

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <IntlContext.Consumer>
                {(context) => (
                    <div className="siteRoot">
                        <CssBaseline />
                        <MessageBox />
                        <AppRouter/>
                    </div>
                )}
            </IntlContext.Consumer>
        </LocalizationProvider>
    );
}

export default App;
