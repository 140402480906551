import {EventArgs, Event, IEventListener, EventListenerInternal} from "./Event";
import * as React from "react";

export class PropertyChangedEventArgs extends EventArgs {
    public Object: any;
    public PropName: string;
    public OldValue: any;
    public NewValue: any;

    public ForProperties(propNames: string[], callback: (args: PropertyChangedEventArgs) => void ) : void {
        if( propNames.Contains(this.PropName) )
            callback(this);
    }

    public toString = () : string => {
        return this.PropName
            ? `${this.Object.constructor.name}.${this.PropName} ${this.OldValue}->${this.NewValue}`
            : `${this.Object.constructor.name} - whole object is changed`;
    }
}

class PropertyChangedEventListener extends EventListenerInternal<PropertyChangedEventArgs> {
    private _props: string[];

    constructor(props: string[], listener: IEventListener<PropertyChangedEventArgs> | React.Component) {
        super(listener);

        this._props = props;
    }

    public ShouldNotify(args: PropertyChangedEventArgs) : boolean {
        return this._props.Contains(args.PropName);
    }
}

export class PropertyChangedEvent extends Event<PropertyChangedEventArgs> {
    // Listen only certain props, ignores other changes
    // Accepts a callback will be executed when event fires.
    // Also accepts the instance of React.Component - c.setState({}) will be executed then
    public ListenProps(props: string[], listener: IEventListener<PropertyChangedEventArgs> | React.Component ) {
        this._listeners.Add(new PropertyChangedEventListener(props, listener));
    }
}

export class NotifyPropertyChanged {
    public readonly PropertyChanged: PropertyChangedEvent = new PropertyChangedEvent();

    public NotifyPropertyChanged(propName: string, oldValue: any, newValue: any) : void {
        const args : PropertyChangedEventArgs  = new PropertyChangedEventArgs();
        args.Object = this;
        args.PropName = propName;
        args.NewValue = newValue;
        args.OldValue = oldValue;
        this.PropertyChanged.Notify( args );
    }

    public NotifyObjectChanged() : void {
        const args : PropertyChangedEventArgs  = new PropertyChangedEventArgs();
        args.Object = this;
        this.PropertyChanged.Notify( args );
    }

    protected SetWithNotification(filedName: string, propName : string, value: any) : void {
        if((!this[filedName] && !value) || (this[filedName] === value)) return;
        const oldValue = this[filedName];
        this[filedName] = value;
        this.NotifyPropertyChanged(propName, oldValue, value);
    }
}
