import {UserModel} from "./Models/UserModel";
import {deserialize, plainToInstance} from "class-transformer";
import {ApiConfigModel} from "./Models/ApiConfigModel";
import {DevOpsWiqlResponse} from "./Models/DevOpsWiql";
import {DevOpsWorkitemsBatchRequest, DevOpsWorkitemsBatchResponse} from "./Models/DevOpsWorkitemsBatch";
import {DevOpsWorkItem} from "./Models/DevOpsWorkItem";
import {BoardCtlr} from "./Components/Board/BoardCtlr";

class FetchOptions {
	constructor(data?: Partial<FetchOptions>) {
		Object.assign(this, data);
	}

	BasicAuthToken: string;
	Method: string;
	ContentType: string;
}

class Api {
	private static _errorHandler: (message: string, error: Error, isSapError: boolean) => void;
	private static user: UserModel;
	private static _apiConfig: ApiConfigModel;
	public static CurrentLanguage: string;

	public static set ErrorHandler(handler: (message: string, error: Error, isSapError: boolean) => void) {
		this._errorHandler = handler;
	}

	public static get CurrentUser(): UserModel {
		return this.user || null;
	}

	public static set CurrentUser(value: UserModel) {
		this.user = value;
	}

	public static get ApiConfig() : ApiConfigModel {
		return this._apiConfig;
	}

	public static async GetCurrentUser(): Promise<UserModel> {
		if (this.CurrentUser !== null) {
			return this.CurrentUser;
		}

		const response = await this.getText(`account/getCurrentUser`);
		const result = plainToInstance(UserModel, response);
		this.CurrentUser = result;

		return result;
	}

	public static async GetApiConfig(): Promise<ApiConfigModel> {
		const response = await this.getText(`account/config`);
		const result = plainToInstance(ApiConfigModel, response);
		this._apiConfig = result;

		return result;
	}

	public static get ImpersonatedUser(): string {
		const savedValue = window.localStorage.getItem("impersonatedUser");
		return JSON.parse(savedValue);
	}

	public static get PreviousImpersonatedUser(): string {
		const previousSavedValue = window.localStorage.getItem("previousImpersonatedUser");
		return JSON.parse(previousSavedValue);
	}

	public static async LoadDevopsWorkItems(): Promise<DevOpsWorkItem[]> {
		const options = new FetchOptions();
		options.BasicAuthToken = BoardCtlr.Instance.Board.DevOpsConnector.Pat;

		const organization = BoardCtlr.Instance.Board.DevOpsConnector.Organization;
		const queryId = BoardCtlr.Instance.Board.DevOpsConnector.Query.Id;

		let queryStr = `https://dev.azure.com/${organization}/_apis/wit/wiql/${queryId}?api-version=6.0`;

		const response = await this.getText(queryStr, options);
		const result = deserialize(DevOpsWiqlResponse, response);

		// Now we have ids, will use workitemsbatch to get items info. Workitems batch can return maximum 200 items (TODO)!
		const ids = result.workItems.map(x => x.id);
		queryStr = `https://dev.azure.com/${organization}/_apis/wit/workitemsbatch?api-version=5.1`;
		const batchResponse = await this.postText(queryStr, new DevOpsWorkitemsBatchRequest( {ids, fields: [
				"System.Id",
				"System.Title",
				"System.WorkItemType",
				"System.State",
				"System.AreaPath",
				"System.Tags",
				"System.TeamProject"
			]} ), options);
		const batchResult = deserialize(DevOpsWorkitemsBatchResponse, batchResponse);

		return batchResult.value.map( x => new DevOpsWorkItem({
			Id: x.id,
			Url: `https://dev.azure.com/${BoardCtlr.Instance.Board.DevOpsConnector.Organization}/${x.fields["System.TeamProject"]}/_workitems/edit/${x.id}`, //x.url,
			Title: x.fields["System.Title"],
			WorkItemType: x.fields["System.WorkItemType"],
			State: x.fields["System.State"],
			Area: x.fields["System.AreaPath"],
			Tags: x.fields["System.Tags"]?.split('; ')
		}));
	}

	public static async SetDevopsWorkItemState(workItemId: string, state: string): Promise<void> {
		this.SetDevopsWorkItemField(workItemId, "System.State", state);
	}

	public static async SetDevopsWorkItemArea(workItemId: string, area: string): Promise<void> {
		this.SetDevopsWorkItemField(workItemId, "System.AreaPath", area);
	}

	private static async SetDevopsWorkItemField(workItemId: string, fieldName: string, fieldValue: any): Promise<void> {
		const organization = BoardCtlr.Instance.Board.DevOpsConnector.Organization;

		const options = new FetchOptions( {
			BasicAuthToken: BoardCtlr.Instance.Board.DevOpsConnector.Pat,
			Method: 'PATCH',
			ContentType: 'application/json-patch+json'
		});

		const queryStr = `https://dev.azure.com/${organization}/_apis/wit/workitems/${workItemId}?api-version=5.1`;
		const body = [
			{
				op: "add",
				path: "/fields/" + fieldName,
				value: fieldValue
			}
		];

		const response = await this.postText(queryStr, body, options);
	}

	private static AddQueryParam(queryStr: string, param: string): string {
		if (!queryStr) queryStr = "?" + param;
		else queryStr += "&" + param;

		return queryStr;
	}

	private static async getJson<OT>(url: string, options?: FetchOptions): Promise<OT> {
		const responseText = await this.getText(url, options);

		const json = responseText ? JSON.parse(responseText) : null;
		return json;
	}

	private static async getText(url: string, options?: FetchOptions): Promise<string | null> {
		const fullUrl = url;

		options = options || new FetchOptions();

		let response;

		let headers = {localizationLang: this.CurrentLanguage};

		if( options?.BasicAuthToken )
			headers["Authorization"] = "Basic " + btoa(":" + options.BasicAuthToken);

		if(this.ImpersonatedUser){
			headers["impersonateContact"] = this.ImpersonatedUser;
		}

		headers["mode"] = "no-cors";

		try {
			// In case of the error in fetch it shows error on the console, but doesn't throw the error.
			// Returns response.ok == false instead.
			response = await fetch(fullUrl, {
				cache: "no-cache",
				//credentials: "include",
				headers: {
					...headers
				},
			});
		} catch (e) {
			await this.HandleError(fullUrl, undefined, e as Error);
			throw e;
		}

		if (!response.ok) {
			await this.HandleError(fullUrl, response);
			return null;
		}

		const responseText = await response.text();

		return responseText;
	}

	///
	/// Does post and returns result as a text
	///
	private static async postText(url: string, data: any, options?: FetchOptions): Promise<string> {
		const fullUrl = url;
		options = options || new FetchOptions();

		let response;

		let headers = {localizationLang: this.CurrentLanguage};

		if( options?.BasicAuthToken )
			headers["Authorization"] = "Basic " + btoa(":" + options.BasicAuthToken);

		if(this.ImpersonatedUser){
			headers["impersonateContact"] = this.ImpersonatedUser;
		}

		headers["mode"] = "no-cors";

		try {
			// In case of the error in fetch it shows error on the console, but doesn't throw the error.
			// Returns response.ok == false instead.
			const json = JSON.stringify(data);

			response = await fetch(fullUrl, {
				method: options.Method || "POST",
				headers: {
					"Content-Type": options.ContentType || "application/json; charset=utf-8",
					...headers,
				},
				body: json,
			});
		} catch (e) {
			await this.HandleError(fullUrl, null, e as Error);
			throw e;
		}

		if (!response.ok) {
			await this.HandleError(fullUrl, response, null);
			return null;
		}

		const responseText = await response.text();

		return responseText;
	}

	///
	/// Does post and returns result as an object, parsed from json
	///
	private static async postJson<OT>(url: string, data: any, options?: FetchOptions): Promise<OT> {
		const responseText = await this.postText(url, data, options);

		const json = responseText ? JSON.parse(responseText) : null;
		return json;
	}

	private static async HandleError(url: string, response?: Response, error?: Error) {
		let errorMessage = error && error.toString();
		const httpStatus = response && response.status;
		const httpStatusText = response && response.statusText;

		if (response) {
			const responseMessage = await response.text();

			try {
				var errorDetails = JSON.parse(responseMessage);
				errorMessage = errorDetails.Message || responseMessage;
			} catch {}
		}

		let message = errorMessage || `Error while fetching data from Api ${url}: ${httpStatus} (${httpStatusText})`;

		const newError = error ? error : new Error(message);
		if (httpStatus) newError["httpStatus"] = httpStatus;
		if (url) newError["url"] = url;

		const sapErrorPrefix = "Sap returned an error.";
		const isSapError = message.startsWith(sapErrorPrefix);
		if(isSapError){
			message = message.substring(sapErrorPrefix.length);
		}
		if (this._errorHandler) this._errorHandler(message, error, isSapError);

		throw newError;
	}
}

export default Api;
