export class DevOpsWiqlResponse {
    public columns: any[];

    public workItems: DevOpsWiqlWorkItem[];
}

export class DevOpsWiqlWorkItem {
    public id: number;

    public url: string;
}
