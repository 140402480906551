import Log from "../Logger";
import MessageBox from "../../Components/Reusable/MessageBox";
import {RouteNames} from "./RouteNames";

export class BrowserHelper {
    private _router: any;
    get _history() : any { return this._router.current.history; }
    // Any Navigate call is not working until Init is executed
    public Init(router: any) {
        this._router = router;
    }

    public NavigateHome() {
        Log.info(`BrowserHelper.NavigateHome`);
        this.EnsureInitialized();
        const route = RouteNames.Home;
        this._history.push(route);
    }

    get IsLogoutPage() {
        return window.location.href.endsWith("/logout");
    }

    private EnsureInitialized() {
        if (MessageBox)
            MessageBox.Close();

        if( !this._router || !this._router.current || !this._router.current.history )
            throw new Error("BrowserHelper is not initialized!");
    }
}
