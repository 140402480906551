import {BaseApi} from "./BaseApi";
import {DevOpsWorkItem} from "../Models/DevOpsWorkItem";
import {deserialize} from "class-transformer";
import {DevOpsWiqlResponse} from "../Models/DevOpsWiql";
import {DevOpsWorkitemsBatchRequest, DevOpsWorkitemsBatchResponse} from "../Models/DevOpsWorkitemsBatch";
import {DevOpsConnectorModel} from "../Models/DevOpsConnectorModel";
import {NameId} from "./NameId";
import {DevOpsQueriesResponse, DevOpsQuery} from "../Controllers/DevOps/DevOpsQueriesResponse";

export class DevOpsRestApi extends BaseApi {
    protected _connector: DevOpsConnectorModel;
    private _devOpsUrl: string = "https://dev.azure.com";
    private _vsOnlineUrl: string = "https://app.vssps.visualstudio.com";

    constructor(connector: DevOpsConnectorModel) {
        super();
        this._connector = connector;
    }

    public async GetQueries() : Promise<NameId<string>[]> {
        const organization = this._connector.Organization;
        const project = this._connector.Project;

        let queryStr = `${this._devOpsUrl}/${organization}/${project}/_apis/wit/queries?api-version=6.0&$depth=2`;

        const response = await this.getText(queryStr);
        const result = deserialize(DevOpsQueriesResponse, response);

        const queries = DevOpsRestApi.FlatQueries(result.value);

        return queries.map( x => {
            return new NameId<string>(x.id, x.path);
        } );
    }

    public async GetOrganizations() : Promise<string[]> {
        let me = await  this.GetMyProfile();

        let queryStr = `${this._vsOnlineUrl}/_apis/accounts?memberId=${me.publicAlias}&api-version=6.0`;
        const response = await this.getJson<any>(queryStr);

        return response.value.Select((x : any) => x.accountName);
    }

    public async GetProjects(organization: string) : Promise<string[]> {
        let me = await  this.GetMyProfile();

        let queryStr = `${this._devOpsUrl}/${organization}/_apis/projects?api-version=5.1`;
        const response = await this.getJson<any>(queryStr);

        return response.value.Select((x : any) => x.name);
    }

    private async GetMyProfile() : Promise<any> {
        // All organizations access token is required
        let queryStr = `${this._vsOnlineUrl}/_apis/profile/profiles/me?api-version=5.1`;
        const response = await this.getJson(queryStr);

        return response;
    }

    public async LoadDevopsWorkItems(): Promise<DevOpsWorkItem[]> {
        const organization = this._connector.Organization;
        const queryId = this._connector.Query.Id;

        let queryStr = `${this._devOpsUrl}/${organization}/_apis/wit/wiql/${queryId}?api-version=6.0`;

        const response = await this.getText(queryStr);
        const result = deserialize(DevOpsWiqlResponse, response);

        // Now we have ids, will use workitemsbatch to get items info. Workitems batch can return maximum 200 items (TODO)!
        const ids = result.workItems.map(x => x.id);
        queryStr = `https://dev.azure.com/${organization}/_apis/wit/workitemsbatch?api-version=5.1`;
        const batchResponse = await this.postText(queryStr, new DevOpsWorkitemsBatchRequest( {ids, fields: [
                "System.Id",
                "System.Title",
                "System.WorkItemType",
                "System.State",
                "System.AreaPath",
                "System.Tags",
                "System.TeamProject"
            ]} ));
        const batchResult = deserialize(DevOpsWorkitemsBatchResponse, batchResponse);

        return batchResult.value.map( x => new DevOpsWorkItem({
            Id: x.id,
            Url: `https://dev.azure.com/${this._connector.Organization}/${x.fields["System.TeamProject"]}/_workitems/edit/${x.id}`, //x.url,
            Title: x.fields["System.Title"],
            WorkItemType: x.fields["System.WorkItemType"],
            State: x.fields["System.State"],
            Area: x.fields["System.AreaPath"],
            Tags: x.fields["System.Tags"]?.split('; ')
        }));
    }

    private static FlatQueries(tree: DevOpsQuery[]) : DevOpsQuery[] {
        return tree.reduce(function(acc, o) {
            if(!o.isFolder)
                acc.push(o);
            if(o.hasChildren && o.children)
                acc = acc.concat(DevOpsRestApi.FlatQueries(o.children));
            return acc;
        }, []);
    }
}
