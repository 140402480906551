export class UserModel {
    public FirstName: string;
    public LastName: string;
    public PreferredLanguage: string;

    public Roles : Role[];

    get FullName(): string {
        return `${this.LastName}, ${this.FirstName}`;
    }

    public get HasAdminRole() : boolean {
         return this.Roles.filter(x=>x.Code===RoleCodes.Admin).length !== 0;
    }
}

export class Role {
    public Code: string;
}

export class RoleCodes {
    public static Admin: string = "Admin";
}