import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LogoWhite from "../Assets/Images/LogoWhite.png";
import "./MiniDrawerLayout.scss"
import MenuIcon from '@mui/icons-material/Menu';
import {alpha, Grid, ListItemIcon, Menu, MenuItem} from "@mui/material";
// @ts-ignore
import {NestedMenuItem} from "mui-nested-menu";
import {AppCtlr} from "../AppCtlr";
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {EditCardDialogInstance} from "./EditCardDialog/EditCardDialog";
import {ColumnsConfigDialogInstance} from "./ColumnsConfigDialog/ColumnsConfigDialog";
import {BoardSelectDialogInstance} from "./BoardSelectDialog/BoardSelectDialog";
import {EditBoardDialogInstance} from "./EditBoardDialog/EditBoardDialog";
import {BoardModel} from "../Models/BoardModel";
import {BoardCtlr} from "./Board/BoardCtlr";
import SettingsIcon from '@mui/icons-material/Settings';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import LogoutIcon from '@mui/icons-material/Logout';
import {StringFilter} from "./Board/Filters";

const drawerWidth = 280;

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '9ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: '48px!important' // dense toolbar
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface IProps {

}

export default function MiniDrawerLayout(props: React.PropsWithChildren<IProps>) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const onAddCard = () => {
        handleMenuClose();
        EditCardDialogInstance.OpenNewCard();
    }

    const onConfigureColumns = () => {
        handleMenuClose();
        ColumnsConfigDialogInstance.Open();
    }

    const onCreateBoard = () => {
        handleMenuClose();
        EditBoardDialogInstance.Open( new BoardModel() );
    }

    const onEditBoard = () => {
        handleMenuClose();
        EditBoardDialogInstance.Open( BoardCtlr.Instance.Board );
    }

    const onChangeBoard = () => {
        handleMenuClose();
        BoardSelectDialogInstance.Open();
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        handleMenuClose();
    }

    const renderMenu = () => {
        return (
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={menuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={onEditBoard}>
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    the-Board Settings
                </MenuItem>
                <MenuItem onClick={onConfigureColumns}>
                    <ListItemIcon>
                        <ViewColumnIcon fontSize="small" />
                    </ListItemIcon>
                    Configure Columns
                </MenuItem>
                <Divider/>
                <MenuItem onClick={onChangeBoard}>
                    <ListItemIcon>
                        <DashboardIcon fontSize="small" />
                    </ListItemIcon>
                    Open the-Board
                </MenuItem>
                <MenuItem onClick={onCreateBoard}>
                    <ListItemIcon>
                        <DashboardCustomizeIcon fontSize="small" />
                    </ListItemIcon>
                    Create new the-Board
                </MenuItem>
                {/*<Divider/>*/}
                {/*<MenuItem onClick={handleSignOut}>*/}
                {/*    <ListItemIcon>*/}
                {/*        <LogoutIcon fontSize="small" />*/}
                {/*    </ListItemIcon>*/}
                {/*    Sign Out*/}
                {/*</MenuItem>*/}
            </Menu>
        );
    }

    const onSearchChanged = (value: string) => {
        if(value)
            BoardCtlr.Instance.Filter = new StringFilter(value);
        else if(!!(BoardCtlr.Instance.Filter as StringFilter))
            BoardCtlr.Instance.Filter = null;
    };

    return (
        <Box sx={{ display: 'flex' }} className={"mini-drawer"}>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar variant="dense">
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item display={{ xs: "none", sm: "block" }}>
                            <div className={"title"} onClick={ () => AppCtlr.Instance.Browser.NavigateHome() }>
                                <img alt={"logo"} src={LogoWhite} className={"logo"}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography variant={"h6"}>
                                { BoardCtlr.Instance.Board?.Name }
                            </Typography>
                        </Grid>
                        {/*<Grid item alignItems={"center"}>*/}
                        {/*    <IconButton>*/}
                        {/*        <AddCircleIcon />*/}
                        {/*    </IconButton>*/}
                        {/*    <IconButton>*/}
                        {/*        <AddCircleIcon />*/}
                        {/*    </IconButton>*/}
                        {/*</Grid>*/}
                        <Grid item>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search…"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={event => onSearchChanged(event.target.value)}
                                />
                            </Search>
                            <Typography noWrap variant={"body2"}>
                                {/*User name here*/}
                            </Typography>
                        </Grid>
                    </Grid>

                    <div className={"buttons"}>
                        <IconButton
                            aria-label="add card"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={onAddCard}
                        >
                            <AddCircleIcon />
                        </IconButton>
                        <IconButton
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        {
                            renderMenu()
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} className={"main"}>
                <DrawerHeader />
                {
                    props.children
                }
            </Box>
        </Box>
    );
}
