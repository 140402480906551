import {CardModel} from "../../Models/CardModel";

export abstract class BaseFilter {
    public abstract Apply(cards: CardModel[]) : [boolean, CardModel][];
}

export class TagFilter extends BaseFilter {
    private _tag: string;

    constructor(tag: string) {
        super();
        this._tag = tag;
    }

    public Apply(cards: CardModel[]): [boolean, CardModel][] {
        return cards.map(x => [x.Tags.Any(t => t === this._tag), x]);
    }

    public get Tag() : string {
        return this._tag;
    }
}

export class StringFilter extends BaseFilter {
    private _string: string;

    constructor(string: string) {
        super();
        this._string = string;
    }

    public Apply(cards: CardModel[]): [boolean, CardModel][] {
        const strLower = this._string.toLowerCase();

        return cards.map(x =>  {
            const hasTag = x.Tags?.Any(t => t.toLowerCase().indexOf(this._string) >= 0);
            const hasTitle = x.Title?.toLowerCase()?.indexOf(this._string) >= 0;
            const hasBody = x.Body?.toLowerCase()?.indexOf(this._string) >= 0;

            return [hasTag || hasTitle || hasBody, x];
        });
    }
}
