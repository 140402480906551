export class CardModel {
    constructor(data?: Partial<CardModel>) {
        Object.assign(this, data);

        if( !this.Width ) this.Width = 100;
        if( !this.Height ) this.Height = 100;
        if( !this.CardType ) this.CardType = CardType.Local;
    }

    public Id: string;

    public PosX: number = 20;

    public PosY: number = 20;

    public Width: number = 100;

    public Height: number = 100;

    public Zindex: number = 0;

    public Title: string;

    public TitleUrl: string;

    public Body: string;

    public Color: string = "#009688";

    public Tags: string[] = [];

    public CardType: CardType;

    public SyncStatus: SyncStatus;

    public IsDeleted: boolean = false;

    public IsHighlight: boolean;

    public DevOpsState: string;

    public DevOpsArea: string;
}

export enum CardType {
    Local = "Local",
    DevOps = "DevOps"
}

export enum SyncStatus {
    NotSynced = "NotSynced",
    SyncInProgres = "SyncInProgres",
    Synced = "Synced"
}
