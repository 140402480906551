import {BaseCtlr} from "./Tools/BaseCtlr";
import {UserModel} from "./Models/UserModel";
import {BrowserHelper} from "./Tools/Routing/BrowserHelper";
import {GetUrlParam} from "./Tools/HttpEx";
import {IDevOpsClient} from "./Controllers/DevOps/IDevOpsClient";
import {DevOpsExtensionClient} from "./Controllers/DevOps/DevOpsExtensionClient";
import {DevOpsClient} from "./Controllers/DevOps/DevOpsClient";
import {BoardCtlr} from "./Components/Board/BoardCtlr";

class SavingInfo {
    constructor(isSaving:boolean, message: string)
    {
        this.IsSaving = isSaving;
        this.Message = message;
    }
    public IsSaving: boolean = false;
    public Message: string;
    public static Default: SavingInfo = new SavingInfo(false, "");
}

export class AppCtlr extends BaseCtlr {
    private static _instance: AppCtlr = new AppCtlr();
    // private static _saving = SavingInfo.Default;
    private _userProfile: UserModel;
    private _isDevOpsExtension: boolean;

    public constructor() {
        super();
        this.Browser = new BrowserHelper();

        this._isDevOpsExtension = GetUrlParam('DevOpsExtension') === 'true'
    }

    public async Initialize(): Promise<void> {
        // if( MsalService.IsAuthenticated )
        //     this._userProfile = await Api.GetUserProfile();
    }

    static get Instance(): AppCtlr {
        return this._instance;
    }

    public Browser: BrowserHelper;

    get UserProfile(): UserModel {
        return this._userProfile;
    }

    get IsSignedIn(): boolean {
        return !!this._userProfile;
    }

    get IsDevOpsExtension() : boolean {
        return this._isDevOpsExtension;
    }

    // /// Gets whether api is executing any save-type operation
    // static get Saving() : SavingInfo {
    //     return this._saving;
    // }
    //
    // public static readonly SavingChanged: Event<EventArgs> = new Event<EventArgs>();
    //
    // public static async WithSaving<TAction>(  message: string, action: () => TAction) {
    //     try {
    //         this.StartSaving(message);
    //         return await action();
    //     } finally {
    //         this.EndSaving();
    //     }
    // }
    //
    // private static StartSaving(message: string){
    //     this._saving = new SavingInfo(true, message);
    //     this.SavingChanged.Notify(new EventArgs());
    // }
    //
    // private static EndSaving(){
    //     this._saving = SavingInfo.Default;
    //     this.SavingChanged.Notify(new EventArgs());
    // }
}
