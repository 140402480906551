import * as React from "react";
import Log from "./Logger";

export class EventArgs {
    public toString = () : string => {
        return `()`;
    }
}

export type IEventListener<TArgs extends EventArgs> = (args: TArgs) => void;

export class EventListenerInternal<TArgs extends EventArgs> {
    private readonly _listener: IEventListener<TArgs> | React.Component;

    constructor( listener: IEventListener<TArgs> | React.Component ) {
        this._listener = listener;
    }

    get Listener() : IEventListener<TArgs> | React.Component {
        return this._listener;
    }

    public ShouldNotify(args: TArgs) : boolean {
        return true;
    }
}

export class Event<TArgs extends EventArgs> {
    protected _listeners: Array<EventListenerInternal<TArgs>>;
    private _suppressed: boolean = false;
    constructor() {
        this._listeners = [];
    }

    // Accepts a callback will be executed when event fires.
    // Also accepts the instance of React.Component - c.setState({}) will be executed then
    public Listen(listener: IEventListener<TArgs> | React.Component ) {
        this._listeners.Add( new EventListenerInternal(listener) );
    }

    public Unlisten(listener: IEventListener<TArgs> | React.Component ) {
        const listeners = this._listeners.Where( x => x.Listener === listener);
        this._listeners.RemoveRange(listeners);
    }

    public Notify(args: TArgs) {
        Log.trace(`${this._suppressed? "SUPPRESSED -> " : ''}Event.Notify: ${args.constructor.name}: '${args.toString()}'. ${this._listeners.length} listeners`);
        if( this._suppressed ) return;
        this._listeners.forEach(x => {
            const listener = x.ShouldNotify(args) ? x.Listener : null;

            if( listener ) {
                if (listener instanceof React.Component) {
                    listener.forceUpdate();
                }
                else
                    listener(args);
            }
        });
    }

    // Sets/Resets event suppressed state
    public Suppress(suppress: boolean) {
        this._suppressed = suppress;
    }
}
