export class Size {
    constructor(width: number, height: number) {
        this.Width = width;
        this.Height = height;
    }

    public Width: number;

    public Height: number;
}

export class Point {
    constructor(x: number, y: number) {
        this.X = x;
        this.Y = y;
    }

    public X: number;

    public Y: number;
}

export class Rectangle {
    constructor(leftX: number, leftY: number, rightX: number, rightY: number) {
        this.LeftTop = new Point(leftX, leftY);
        this.RightBottom = new Point(rightX, rightY);
        this.Size = new Size(rightX - leftX, rightY - leftY);
    }

    public LeftTop: Point;

    public RightBottom: Point;

    public Size: Size;

    public IsIntersect(rect: Rectangle) {
        return !(this.LeftTop.X > rect.RightBottom.X ||
            this.RightBottom.X < rect.LeftTop.X ||
            this.LeftTop.Y > rect.RightBottom.Y ||
            this.RightBottom.Y < rect.LeftTop.Y);
    }
}
