import React from "react";
import {BaseComponent} from "../Reusable/BaseComponent";
import ValidationMgr from "../../Tools/ValidationMgr";
import {Autocomplete, Button, Checkbox, Dialog, FormControlLabel, Grid, IconButton, InputAdornment, TextField, Theme, Typography} from "@mui/material";
import {useTheme} from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {ColumnList} from "../../Models/ColumnList";
import {deserializeArray, serialize} from "class-transformer";
import {ColumnModel, MappingType} from "../../Models/ColumnModel";
import "./ColumnsConfigDialog.scss"
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {Validation} from "../../Tools/Validation";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Guid} from "js-guid";
import {BoardCtlr} from "../Board/BoardCtlr";

// Need a unique Id when dragging
class ColumnModelWithId extends ColumnModel {
    constructor(data?: Partial<ColumnModelWithId>) {
        super(data);
        Object.assign(this, data);

        this.Id = Guid.newGuid().toString();
    }

    public Id: string;

    public ToColumnModel() : ColumnModel {
        return new ColumnModel( {Title: this.Title, Width: this.Width, Mapping: this.Mapping} );
    }
}

interface IState {
    IsOpen: boolean;
    Columns: ColumnModelWithId[];
    MapTo: MappingType;
    SyncToDevOps: boolean;
};

interface IProps{
    IsFullScreen: boolean;
}

class ColumnsConfigDialogInternal extends BaseComponent<IProps, IState> {
    private _validationMgr: ValidationMgr = new ValidationMgr();
    public static _instance: ColumnsConfigDialogInternal;

    constructor(props: IProps) {
        super(props);

        this.state ={
            IsOpen: false,
            Columns: [],
            MapTo: ColumnList.Instance.ColumnsConfig.MapTo,
            SyncToDevOps: ColumnList.Instance.ColumnsConfig.SyncToDevOps
        };
        ColumnsConfigDialogInternal._instance = this;
    }

    private Close = () => {
        this.setState( { IsOpen: false} );
    }

    private OnSaveClick = () => {
        if( this._validationMgr.HasError )
        {
            this._validationMgr.ErrorsDisplayed = true;
            this.forceUpdate();
            return;
        }

        ColumnList.Instance.Save( BoardCtlr.Instance.Board.Id, this.state.Columns.map( (x, index) => {
            const result = x.ToColumnModel();
            result.Order = index + 1;
            return result;
        }), this.state.MapTo, this.state.SyncToDevOps);

        this.Close();
    }

    private RenderTitleRow = () => {
        return (
            <Grid container spacing={2} className={"title"} >
                <Grid item className={"drag-col"}>
                </Grid>
                <Grid item xs={true} className={"title-col"}>
                    <Typography noWrap variant={"subtitle2"}>
                        Title
                    </Typography>
                </Grid>
                <Grid item className={"width-col"}>
                    <Typography noWrap variant={"subtitle2"} className={"allign-number"}>
                        Width
                    </Typography>
                </Grid>
                <Grid item className={"action-col"}>
                    <IconButton aria-label="add" size={"large"} onClick={ () => this.OnAddColumn() }>
                        <AddIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        );
    }

    private RenderTotalsRow = () => {
        return (
            <Grid container spacing={2} className={"totals"}>
                <Grid item className={"drag-col"}>
                </Grid>
                <Grid item xs={true}>
                </Grid>
                <Grid item className={"width-col"}>
                    <Typography noWrap variant={"subtitle1"} className={"allign-number"}>
                        { this.state.Columns.Sum(x => x.Width) }&nbsp;%
                    </Typography>
                </Grid>
                <Grid item className={"action-col"}>
                </Grid>
            </Grid>
        );
    }

    private RenderColumnRow = (column: ColumnModelWithId) => {
        const textFiledDefaultProps = {margin: "dense", fullWidth: true }

        return (
            <div className={"row-container"}>
                <Grid container key={"info_" + this.state.Columns.indexOf(column)} columnSpacing={2}>
                    <Grid item className={"drag-col"}>
                        <DragIndicatorIcon />
                    </Grid>
                    <Grid item xs={true}>
                        <TextField
                            autoFocus
                            { ...textFiledDefaultProps }
                            required
                            { ...this.BindObject(column, "Title") }
                            {...this._validationMgr.Validate(column.Title, Validation.Required() )}
                        />
                    </Grid>
                    <Grid item className={"width-col"}>
                        <TextField
                            className={"allign-number"}
                            type={"number"}
                            { ...textFiledDefaultProps }
                            required
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            { ...this.BindObject(column, "Width", "number") }
                            {...this._validationMgr.Validate(column.Width, Validation.Required() )}
                        />
                    </Grid>
                    <Grid item className={"action-col"}>
                        <IconButton aria-label="delete" size={"large"} onClick={ () => this.OnDeleteColumn(column) }>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container key={"map_" + this.state.Columns.indexOf(column)} columnSpacing={2}>
                    <Grid item className={"drag-col"}>
                    </Grid>
                    <Grid item xs={true}>
                        <TextField
                            margin={"normal"}
                            fullWidth
                            value={column.Mapping && column.Mapping.length > 0 ? column.Mapping[0] : ""}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><b>Mappings:</b></InputAdornment>,
                            }}
                            onChange={event => {
                                column.Mapping = event.target.value ? [event.target.value] : [];
                                this.forceUpdate();
                            }}
                        />
                    </Grid>
                    <Grid item className={"action-col"}>
                    </Grid>
                </Grid>
            </div>
        );
    }

    private OnDeleteColumn = (column: ColumnModelWithId) => {
        this.state.Columns.Remove(column);
        this.forceUpdate();
    }

    private OnAddColumn = () => {
        this.state.Columns.push( new ColumnModelWithId ({Width: 10, Id: Guid.newGuid().toString()}) );
        this.forceUpdate();
    }

    private RenderDraggableColumnss = () => {
        const onDragEnd = (result: any) => {
            // dropped outside the list
            if (!result.destination) {
                return;
            }

            const reorder = (list: ColumnModelWithId[], startIndex: number, endIndex: number) : ColumnModelWithId[] => {
                const result = Array.from(list);
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);

                return result;
            };

            const items = reorder(
                this.state.Columns,
                result.source.index,
                result.destination.index
            );

            this.setState({
                Columns: items
            });
        }

        const getItemStyle = (isDragging: any, draggableStyle: any) => ({
            // change background colour if dragging
            background: isDragging ? "lightgray" : "inherit",

            // styles we need to apply on draggables
            ...draggableStyle
        });

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided: any, snapshot: any) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {this.state.Columns.map((item, index) => (
                                <Draggable key={item.Id} draggableId={item.Id} index={index}>
                                    {(provided: any, snapshot: any) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {this.RenderColumnRow(item)}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    render() {
        console.log("ColumnsConfigDialogInternal.render");

        this._validationMgr.StartValidation();

        const isOpen = this.state.IsOpen;

        const mappingTypeOptions = [
            MappingType.State,
            MappingType.Area
        ];

        return (
            isOpen &&
            <Dialog
                fullScreen={this.props.IsFullScreen}
                open={true}
                aria-labelledby="responsive-dialog-title"
                classes={{paper: "columns-config-dialog nscrollable"}}
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Configure board Columns"}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={true}>
                            <Autocomplete
                                disablePortal
                                options={mappingTypeOptions}
                                renderInput={(params) => <TextField
                                    {...params}
                                    margin={"normal"}
                                    { ...this.WithLabel("Map to DevOps field")}
                                />}
                                value={this.state.MapTo || ""}
                                onChange={(event, newValue: any) => {
                                    this.setState( {MapTo: newValue} );
                                }}
                            />
                        </Grid>
                        <Grid item className={"sync-to-do"}>
                            <FormControlLabel control={
                                    <Checkbox
                                        checked={!!this.state.SyncToDevOps}
                                        onChange={ (event) => this.setState( {SyncToDevOps: event.target.checked} ) } />
                                } label="Sync to DevOps atomatically"
                            />
                        </Grid>
                    </Grid>
                    {
                        this.RenderTitleRow()
                    }
                    {
                        this.RenderDraggableColumnss()
                    }
                    {
                        this.RenderTotalsRow()
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.Close}>
                        Cancel
                    </Button>
                    <Button onClick={this.OnSaveClick} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default function ColumnsConfigDialog() {
    const theme : Theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <ColumnsConfigDialogInternal IsFullScreen={fullScreen}/>
    );
}

export class ColumnsConfigDialogInstance {
    public static Open() {
        const json = serialize(ColumnList.Instance.Columns);
        const clone = deserializeArray(ColumnModel, json)

        ColumnsConfigDialogInternal._instance.setState({
            IsOpen: true,
            Columns: clone.map(x => new ColumnModelWithId(x)),
            SyncToDevOps: ColumnList.Instance.ColumnsConfig.SyncToDevOps,
            MapTo: ColumnList.Instance.ColumnsConfig.MapTo
        });
    }
}
