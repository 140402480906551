import React, {Component} from "react";
import {Button, Dialog, IconButton, List, ListItem, ListItemAvatar, ListItemText, Theme, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useTheme} from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Divider from "@mui/material/Divider";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import "./BoardSelectDialog.scss"
import EditIcon from '@mui/icons-material/Edit';
import {BoardCtlr} from "../Board/BoardCtlr";
import {EditBoardDialogInstance} from "../EditBoardDialog/EditBoardDialog";
import {BoardModel} from "../../Models/BoardModel";

interface IState {
    IsOpen: boolean;
}

interface IProps{
    IsFullScreen: boolean;
}

export class BoardSelectDialogInternal extends Component<IProps, IState> {
    public static _instance: BoardSelectDialogInternal;

    constructor(props: IProps) {
        super(props);

        this.state = {
            IsOpen: false
        };

        BoardSelectDialogInternal._instance = this;
    }

    private Close = () => {
        this.setState( { IsOpen: false} );
    }

    private OnCreateClick = () => {
        EditBoardDialogInstance.Open( new BoardModel());
        this.Close();
    }

    private OnSelectBoard = (board: BoardModel) => {
        BoardCtlr.Instance.SetBoard(board);
        this.Close();
    }

    private RenderBoards() {
        return (
            <>
                {
                    BoardCtlr.Instance.Boards.map( (x, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ListItem alignItems="flex-start" className={"board-item"}>
                                    <ListItemAvatar>
                                        <IconButton size={"large"} className={"open-board-btn"} onClick={() => this.OnSelectBoard(x)}>
                                            <FolderOpenIcon fontSize={"large"}/>
                                        </IconButton>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary= {x.Name}
                                        secondary={
                                            <React.Fragment>
                                                {/*<Typography*/}
                                                {/*    sx={{ display: 'inline' }}*/}
                                                {/*    component="span"*/}
                                                {/*    variant="body2"*/}
                                                {/*    color="text.primary"*/}
                                                {/*>*/}
                                                {/*    Ali Connors*/}
                                                {/*</Typography>*/}
                                                {x.Description}

                                                {/*<IconButton size={"medium"} className={"edit-board-btn"}>*/}
                                                {/*    <EditIcon fontSize={"medium"}/>*/}
                                                {/*</IconButton>*/}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        );
                    })
                }

            </>
        );
    }

    render() {
        const isOpen = this.state.IsOpen;

        return (
            isOpen &&
            <Dialog
                fullScreen={this.props.IsFullScreen}
                open={true}
                classes={{paper: "board-select-dialog nscrollable"}}
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Open the-Board"}
                </DialogTitle>
                <DialogContent>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {
                            this.RenderBoards()
                        }
                    </List>
                </DialogContent>
                <DialogActions className={"actions"}>
                    <Button autoFocus onClick={this.OnCreateClick} className={"create-btn"} >
                        Create
                    </Button>
                    <Button autoFocus onClick={this.Close} disabled={!BoardCtlr.Instance.Board}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default function BoardSelectDialog() {
    const theme : Theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <BoardSelectDialogInternal IsFullScreen={fullScreen}/>
    );
}

export class BoardSelectDialogInstance {
    public static Open() {
        // const json = serialize(ColumnList.Instance.Columns);
        // const clone = deserializeArray(ColumnModel, json)

        BoardSelectDialogInternal._instance.setState({
            IsOpen: true,
            //Columns: clone.map(x => new ColumnModelWithId(x))
        });
    }
}
