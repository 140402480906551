import {createTheme} from "@mui/material";

export const AppTheme = createTheme({
    components: {
        MuiTextField: {
            defaultProps: {
                variant: "standard",
                fullWidth: true,
                margin: "normal"
            }
        },

        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: "#57607B",
                    color: "#EAEFFB",
                    minHeight: "100px",
                    textAlign: "center",
                    fontSize: "1.75rem",
                    lineHeight: "60px"
                }
            }
        },

        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    backgroundColor: "#57607B"
                }
            }
        },
    }
});
