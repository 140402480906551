import React, {Component} from "react";
import "./Card.scss"
import "./CardResizable.css"
import Draggable, {DraggableData, DraggableEvent} from "react-draggable";
import {CardModel, CardType} from "../../Models/CardModel";
import {Resizable, ResizeCallbackData} from "react-resizable";
import {BoardCtlr} from "./BoardCtlr";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Button, IconButton} from "@mui/material";
import MessageBox from "../Reusable/MessageBox";
import {EditCardDialogInstance} from "../EditCardDialog/EditCardDialog";
import {TagFilter} from "./Filters";
import { ReactComponent as DevOpsIcon } from "./azure_devops.svg";

interface IState {
    //IsDragging: boolean,
    IsEditTitle: boolean
}

interface IProps{
    Model: CardModel;
    FilteredOut?: boolean;
}

export class Card extends Component<IProps, IState> {
    private readonly _editRef: React.RefObject<any>;

    constructor(props: IProps) {
        super(props);
        this._editRef = React.createRef();

        this.state ={
            //IsDragging: false,
            IsEditTitle: false
        };
    }

    private OnDragStop = (e: any, position: any) => {
        //console.log(position);

        const col = BoardCtlr.Instance.FocusedColumn;

        BoardCtlr.Instance.DraggingCard = null;
        BoardCtlr.Instance.SaveCardPosition(this.props.Model, position.x, position.y, col);
        this.forceUpdate();
    };

    private OnDragStart = (e1: any, e2: any) => {
        this.CardOnTop();
        BoardCtlr.Instance.DraggingCard = this.props.Model;
        this.forceUpdate();
        //this.setState( {IsDragging: true});
    };


    private OnDrag = (e: DraggableEvent, data: DraggableData) : void | false => {
        console.log(e);
        console.log(data);

        if( data.x < 0 || data.y < 0 ) return false;
    }

    private CardOnTop() {
        let shouldUpdate = this.props.Model.IsHighlight;
        this.props.Model.IsHighlight = false;

        if( this.props.Model.Zindex < BoardCtlr.Instance.MaxZindex ) {
            this.props.Model.Zindex = BoardCtlr.Instance.MaxZindex + 1;
            BoardCtlr.Instance.MaxZindex = BoardCtlr.Instance.MaxZindex + 1;
            shouldUpdate = true;
        }

        if( shouldUpdate ) this.forceUpdate();
    }

    private OnResize = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
        this.props.Model.Width = data.size.width;
        this.props.Model.Height = data.size.height;
        this.forceUpdate();
    };

    private onResizeStart = () => {
        this.CardOnTop();
    }

    private onResizeStop = () => {
        BoardCtlr.Instance.SaveCardSize(this.props.Model, this.props.Model.Width, this.props.Model.Height);
    }

    private OnDeleteCard = () => {
        const card = this.props.Model;

        BoardCtlr.Instance.DeleteCard(card);

        MessageBox.Info("Card has beed deleted", () => {
            return (<Button variant={"text"}
                            size={"small"}
                            color="inherit"
                            onClick={() => {
                                BoardCtlr.Instance.RestoreCard(card);
                                MessageBox.Close();
                            }
                            }>Restore</Button>);
        });
    }

    private OnEditCard = () => {
        const card = this.props.Model;
        EditCardDialogInstance.OpenEditCard(card);
    }

    private RenderBlock = (areaName: string, text: string, link: string, isEdit: boolean, style: any) => {
        if (isEdit) {
            const textAreaStyle = {gridArea: areaName, overflow: 'hidden', ...style };

            return (
                <textarea className={"edit"} style={textAreaStyle} value={text}
                          ref={this._editRef}
                          onBlur={this.OnStopEdit}
                          onChange={this.OnEditChange}
                          autoFocus
                />
            );
        }

        const spanStyle = {...(link ? {cursor: 'pointer'} : {})};
        const span = <span className={"ndraggable view"} style={spanStyle} onClick={this.OnStartEdit}> {text} </span>;

        const innerElement = link
            ? <>
                {" "}
                <a href={link} className={"ndraggable link"} target="_blank" rel="noopener noreferrer">{span}</a>
            </>
            : span;

        return (
            <p className={"para"}  style={{gridArea: areaName, ...style}}>
                {
                    innerElement
                }
            </p>
        );
    }

    private RenderIconBlock = (card: CardModel, areaName: string) => {
        const icon = this.GetIcon(card);

        return (
            <p className={"para " + areaName}  style={{gridArea: areaName}}>
                <span className={"ndraggable view" + (card.IsHighlight ? " hgl" : "")}>
                    { icon }
                </span>
            </p>
        );
    }

    private RenderCardActionsBlock = () => {
        const areaName = "actions";
        const card = this.props.Model;

        return (
            <p className={"para " + areaName}  style={{gridArea: areaName}}>
                <span className={"ndraggable view"}>
                    <IconButton aria-label="edit" size="small" onClick={ this.OnEditCard }>
                            <EditIcon fontSize="inherit"/>
                    </IconButton>
                    {
                        card.CardType === CardType.Local &&
                        <IconButton aria-label="delete" size="small" onClick={ this.OnDeleteCard }>
                            <DeleteIcon fontSize="inherit"/>
                        </IconButton>
                    }
                </span>
            </p>
        );
    }

    render() {
        //this._validationMgr.StartValidation();
        console.log("Card.render");

        const card = this.props.Model;
        const filteredOut = this.props.FilteredOut;

        const style = {
            // cursor: BoardCtlr.Instance.DraggingCard === this.props.Model ? "grabbing" : "grab",
            width: card.Width,
            height: card.Height,
            zIndex: card.Zindex,
            backgroundColor: card.Color
        };

        return (
            <Draggable defaultPosition={{y: card.PosY, x: card.PosX}} onStop={this.OnDragStop} onStart={this.OnDragStart}
                       cancel={"span.react-resizable-handle, .ndraggable"}
            >
                <Resizable height={card.Height} width={card.Width} onResize={this.OnResize} onResizeStart={this.onResizeStart} onResizeStop={this.onResizeStop}>
                    <div className={"card-container nscrollable" + (filteredOut ? " filtered-out" : "")} style={style}>
                        {
                            this.RenderBlock("title", card.Title, card.TitleUrl, this.state.IsEditTitle, {fontWeight: "bold"})
                        }
                        {
                            this.RenderBlock("body", card.Body, null, false, {overflowY: "hidden"})
                        }
                        {
                            this.RenderTagsBlock()
                        }
                        {
                            this.RenderCardActionsBlock()
                        }
                        {
                            this.RenderIconBlock(card, "status")
                        }
                    </div>
                </Resizable>
            </Draggable>
        )
    }

    private GetIcon = (card: CardModel) : React.ReactElement => {
        if( card.CardType === CardType.DevOps )
            return <DevOpsIcon />;  //(<img src={AzureDevopsIcon} />);  //return (<DevOpsIcon1 />);

            // return (<img src={DevOpsIcon} />);
        // if( card.CardType === CardType.Local )
        //     return (<LocalIcon />);

        return null;
    }

    private OnStartEdit = async (event: any) => {
        return;
        await this.setState({IsEditTitle: true});
        this._editRef.current.setSelectionRange(this._editRef.current.value.length, this._editRef.current.value.length);
    }

    private OnStopEdit = () => {
        this.setState({IsEditTitle: false});
    }

    private OnEditChange = (event: any) => {
        this.props.Model.Title = event.target.value;
        this.forceUpdate();
    }

    private RenderTagsBlock = () => {
        const areaName = "tags";
        const card = this.props.Model;

        return (
            <p className={"para " + areaName}  style={{gridArea: areaName}}>
                <span className={"ndraggable view"}>
                    {
                        card.Tags.map( (x, index) => {
                            const isClicked = (BoardCtlr.Instance.Filter as TagFilter)?.Tag === x;
                            return <span key={index} className={"tag" + (isClicked ? " clicked" : "")} onClick={ () => this.OnTagClick(x) }>{x}</span>;
                        })
                    }
                </span>
            </p>
        );
    }

    private OnTagClick = (tag: string) => {
        let dropFilter = false;

        if( BoardCtlr.Instance.Filter ) {
            if( (BoardCtlr.Instance.Filter as TagFilter)?.Tag === tag ) dropFilter = true;
        }

        BoardCtlr.Instance.Filter = dropFilter ? null : new TagFilter(tag);
    }
}

