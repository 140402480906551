import {IDevOpsClient} from "./IDevOpsClient";

import * as SDK from "azure-devops-extension-sdk";
import {CommonServiceIds, IProjectPageService} from "azure-devops-extension-api";
import {FetchOptions} from "../../Tools/BaseApi";
import {DevOpsConnectorModel} from "../../Models/DevOpsConnectorModel";
import {DevOpsRestApi} from "../../Tools/DevOpsRestApi";

export class DevOpsExtensionClient extends DevOpsRestApi implements IDevOpsClient {
    constructor(connector: DevOpsConnectorModel) {
        super(connector);
    }

    // public async GetQueries() : Promise<NameId<string>[]> {
    //     // @ts-ignore
    //     const projectService = await SDK.getService<IProjectPageService>(CommonServiceIds.ProjectPageService);
    //     const project = await projectService.getProject();
    //     const witClient = await getClient(WorkItemTrackingRestClient);
    //     const queries = await witClient.getQueries(project.id, QueryExpand.All, 2);
    //
    //     const expandedQueries = queries.SelectMany(x => x.isFolder ? x.children ? [...x.children] : [] : [x]);
    //
    //     return expandedQueries.map(x => {
    //         return new NameId<string>(x.id, x.name);
    //     });
    // }

    public async GetCurrentProject() : Promise<string> {
        // @ts-ignore
        const projectService = await SDK.getService<IProjectPageService>(CommonServiceIds.ProjectPageService);
        const project = await projectService.getProject();

        return project.name;
    }

    public async GetCurrentOrganization() : Promise<string> {
        const host = await SDK.getHost();
        return host.name;
    }

    // public async GetOrganizations() : Promise<string[]> {
    //     throw new Error("Not implemented");
    // }
    //
    // public async GetProjects(organization: string) : Promise<string[]> {
    //     throw new Error("Not implemented");
    // }

    protected override async GetFetchOptions() : Promise<FetchOptions> {
        return new FetchOptions( {BasicAuthToken: await SDK.getAccessToken()} );
    }
}
