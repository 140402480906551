export class DevOpsWorkitemsBatchRequest {
    constructor(data?: Partial<DevOpsWorkitemsBatchRequest>) {
        Object.assign(this, data);
    }

    public ids: number[];

    public fields: string[];
}

export class DevOpsWorkitemsBatchResponse {
    public count: number;

    public value: DevOpsWorkitemsBatchResponseItem[];
}

export class DevOpsWorkitemsBatchResponseItem {
    public id: number;
    public fields: object;
    public url: string;
}
