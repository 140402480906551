import React from "react";
import {Checkbox, FormControlLabel, Grid, TextField} from "@mui/material";
import {NameId} from "../../Tools/NameId";
import {AutocompleteHelper} from "../Reusable/AutocompleteHelper";
import {DevOpsConnectorModel} from "../../Models/DevOpsConnectorModel";
import ValidationMgr from "../../Tools/ValidationMgr";
import {BaseComponent} from "../Reusable/BaseComponent";
import {DevOpsExtensionClient} from "../../Controllers/DevOps/DevOpsExtensionClient";
import {AppCtlr} from "../../AppCtlr";

interface IState {
    IsQueryLoading: boolean;
    IsOrganizationsLoading: boolean;
    IsProjectsLoading: boolean;
}

interface IProps {
    DevOpsConnector: DevOpsConnectorModel
}

export class DevOpsExtensionConnector extends BaseComponent<IProps, IState> {
    private _validationMgr: ValidationMgr = new ValidationMgr();
    private _queries: NameId<string>[] = [];
    private _organizations: string[] = [];
    private _projects: string[] = [];

    constructor(props: IProps) {
        super(props);

        this.state = {
            IsQueryLoading: false,
            IsOrganizationsLoading: false,
            IsProjectsLoading: false
        };
    }

    async componentDidMount() {
        const connector = this.props.DevOpsConnector;
        const client = new DevOpsExtensionClient(connector);
        connector.Organization = await client.GetCurrentOrganization();
        connector.Project = await client.GetCurrentProject();
        this.forceUpdate();

        this.TryLoadDevOpsQueries();
    }

    private WithRender = (action: () => void) => {
        action();
        this.forceUpdate();
    }

    private CanLoadQueries() : boolean {
        const connector = this.props.DevOpsConnector;
        return connector.IsConnected;
    }

    private async TryLoadDevOpsQueries() {
        if( !this.CanLoadQueries() ) return;

        this.setState({IsQueryLoading: true});

        try {
            const devOpsClient = new DevOpsExtensionClient(this.props.DevOpsConnector);
            this._queries = await devOpsClient.GetQueries();
        } finally {
            this.setState({IsQueryLoading: false});
        }
    }

    render() {
        console.log("DevOpsExtensionConnector.render");

        const connector = this.props.DevOpsConnector;
        this._validationMgr.StartValidation();

        return (
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel control={
                        <Checkbox
                            checked={!!connector.IsConnected}
                            onChange={async (event) => {
                                this.WithRender( () => connector.IsConnected = event.target.checked);
                                await this.TryLoadDevOpsQueries();
                            }}/>
                    } label="Display Azure DevOps Work Items on the-Board"
                    />
                    <TextField
                        label={"Organization"}
                        value={connector.Organization || ""}
                        disabled
                    />
                    <TextField
                        label={"Project"}
                        value={connector.Project || ""}
                        disabled
                    />
                    {
                        AutocompleteHelper.RenderAutocomplete("Query",
                            <>DevOps query to be used when loading Work Items on the-Board.<br/>You can configure query in your Azure DevOps portal (Boards -&gt; Queries)</>,
                            !this.CanLoadQueries(), this._queries, this.state.IsQueryLoading,
                            connector.Query, async (event, newValue) => {
                                this.WithRender( () => connector.Query = newValue);
                            }, (option) => option.Name || "")
                    }
                </Grid>
            </Grid>
        )
    }
}

