import {ICardsStorage} from "./ICardsStorage";
import {CardModel, CardType, SyncStatus} from "../Models/CardModel";
import {BoardModel} from "../Models/BoardModel";
import {ColumnModel, MappingType} from "../Models/ColumnModel";
import {LocalCardsStorage} from "./LocalCardsStorage";
import Api from "../Api";
import {ColumnList} from "../Models/ColumnList";
import {AppCtlr} from "../AppCtlr";
import {BoardCtlr} from "../Components/Board/BoardCtlr";

export class DevOpsCardsStorage implements ICardsStorage{
    private _local : LocalCardsStorage;
    private _board: BoardModel;

    constructor(board: BoardModel) {
        this._board = board;
        this._local = new LocalCardsStorage(board);
    }

    async LoadCards(): Promise<CardModel[]> {
        const localCards = await this._local.LoadCards();

        const response = await BoardCtlr.Instance.DevOpsClient.LoadDevopsWorkItems();

        const devOpsItems = response;

        // Sync Devops items and saved cards
        for (let i = 0; i < localCards.length; i++) {
            const loc = localCards[i];
            const doi = devOpsItems.SingleOrDefault(x => x.Id.toString() === loc.Id);

            if (doi) {
                loc.Title = "#" + doi.Id.toString();
                loc.TitleUrl = doi.Url;
                loc.Body = doi.Title;
                loc.CardType = CardType.DevOps;
                loc.DevOpsState = doi.State;
                loc.DevOpsArea = doi.Area;
                loc.Tags = doi.Tags || [];
                devOpsItems.Remove(doi);
            } else {
                loc.CardType = CardType.Local;
            }
        }

        for (let i = 0; i < devOpsItems.length; i++) {
            const doi = devOpsItems[i];
            // New card came from DevOps
            const card = new CardModel({
                Id: doi.Id.toString(),
                Title: "#" + doi.Id.toString(),
                TitleUrl: doi.Url,
                Body: doi.Title,
                CardType: CardType.DevOps,
                IsHighlight: true,
                DevOpsState: doi.State,
                DevOpsArea: doi.Area,
                Tags: doi.Tags || []
            });
            card.Zindex = localCards.Max(x => x.Zindex)?.Zindex || 1;

            localCards.push(card);
            await this._local.SaveCard(card);
        }

        const result = localCards;

        return Promise.resolve(result);
    }

    async AddCard(card: CardModel): Promise<void> {
        await this._local.AddCard(card);
        return Promise.resolve(undefined);
    }

    async DeleteCard(card: CardModel): Promise<void> {
        await this._local.DeleteCard(card);
        return Promise.resolve(undefined);
    }

    async RestoreCard(card: CardModel): Promise<void> {
        await this._local.RestoreCard(card);
        return Promise.resolve(undefined);
    }

    async SaveCard(card: CardModel): Promise<void> {
        await this._local.SaveCard(card);
        return Promise.resolve(undefined);
    }

    async SaveCardPosition(card: CardModel, posX: number, posY: number, column: ColumnModel): Promise<void> {
        await this._local.SaveCardPosition(card, posX, posY, column);

        if ( ColumnList.Instance.ColumnsConfig.SyncToDevOps && card.CardType === CardType.DevOps) {
            const colMapping = ColumnList.Instance.GetColumnMapping(column.Title);

            if( colMapping ) {
                if( ColumnList.Instance.ColumnsConfig.MapTo == MappingType.State )
                    await Api.SetDevopsWorkItemState(card.Id, colMapping);
                else if( ColumnList.Instance.ColumnsConfig.MapTo == MappingType.Area )
                    await Api.SetDevopsWorkItemArea(card.Id, colMapping);
            }
        }

        return Promise.resolve(undefined);
    }

    async SaveCardSize(card: CardModel, width: number, height: number): Promise<void> {
        await this._local.SaveCardSize(card, width, height);
        return Promise.resolve(undefined);
    }
}
