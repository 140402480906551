/* eslint-disable no-extend-native */

import {instanceToInstance} from "class-transformer";

export{}
declare global {
    interface Array<T> {
        LastOrDefault(): T | null;

        Last(): T | null;

        First(): T | null;

        FirstOrDefault(predicate?: (value: T, index: number, obj: T[]) => boolean): T | null;

        Single(predicate: (value: T, index: number, obj: T[]) => boolean): T;

        SingleOrDefault(predicate: (value: T, index: number, obj: T[]) => boolean): T | null;

        Any(predicate?: (value: T, index: number, obj: T[]) => boolean): boolean;

        All(predicate?: (value: T, index: number, obj: T[]) => boolean): boolean;

        Where(predicate: (value: T, index: number, obj: T[]) => boolean): T[];

        IndexOf(predicate: (value: T, index: number, obj: T[]) => boolean): number;

        OrderBy(selector: (value: T) => any) : T[];

        OrderByDescending(selector: (value: T) => any) : T[];

        // Returns true if item is successfully removed; otherwise, false. This method also returns false if item was not found in the array
        Remove(value: T): boolean;

        RemoveRange(values: T[]): void;

        RemoveRangeWhere(predicate: (value: T, index: number, obj: T[]) => boolean): void;

        // Returns true if item is successfully removed; otherwise, false. This method also returns false if item was not found in the array
        Add(value: T): void;

        Distinct(keySelector: (value: T, index: number, obj: T[]) => string | number): T[];

        Min(selector: (value: T) => any): T;

        Max(selector: (value: T) => any): T;

        Contains(value: T) : boolean;

        GroupBy<Tk>(selector: (value: T) => Tk) : Map<Tk, T[]>;

        Sum(selector: (value: T) => number) : number;

        Count(predicate: (value: T, index: number, obj: T[]) => boolean) : number;

        SelectMany<TOut>(selector: (value: T) => TOut[]) : TOut[];

        Select<TOut>(selector: (value: T) => TOut) : TOut[];

        DeepClone() : T[];
    }
}

if (!Array.prototype.SelectMany) {
    Array.prototype.SelectMany = function<T, TOut>(selector: (value: T) => TOut[]): TOut[] {
        return this.reduce((out, inx) => {
            out.push(...selector(inx));
            return out;
        }, new Array<TOut>());
    }
}

if (!Array.prototype.Select) {
    Array.prototype.Select = function<T, TOut>(selector: (value: T) => TOut): TOut[] {
        return this.map(x => selector(x));
    }
}

if (!Array.prototype.Contains) {
    Array.prototype.Contains = function<T>(value: T): boolean {
        return this.indexOf(value) >= 0;
    }
}

if (!Array.prototype.LastOrDefault) {
    Array.prototype.LastOrDefault = function<T>(): T | null {
        return this.length === 0 ? null : this[this.length-1];
    }
}

if (!Array.prototype.Last) {
    Array.prototype.Last = function<T>(): T | null {
        const result = this.LastOrDefault();

        if( !result )
            throw new Error('Array does not contains items');

        return result;
    }
}

if (!Array.prototype.First) {
    Array.prototype.First = function<T>(): T | null {
        if( this.length === 0 )
            throw new Error('Array does not contains items');

        return this[0];
    }
}

if (!Array.prototype.Any) {
    Array.prototype.Any = function<T>(predicate?: (value: T, index: number, obj: T[]) => boolean): boolean {
        return !!this.FirstOrDefault(predicate);
    }
}

if (!Array.prototype.All) {
    Array.prototype.All = function<T>(predicate?: (value: T, index: number, obj: T[]) => boolean): boolean {
        for(let i=0; i<this.length; i++)
        {
            if( !predicate(this[i], i, this) )
                return false;
        }

        return true;
    }
}

if (!Array.prototype.FirstOrDefault) {
    Array.prototype.FirstOrDefault = function<T>(predicate?: (value: T, index: number, obj: T[]) => boolean): T | null {
        for(let i=0; i<this.length; i++)
        {
            if( !predicate || predicate(this[i], i, this) )
                return this[i];
        }

        return null;
    }
}

if (!Array.prototype.Single) {
    Array.prototype.Single = function<T>(predicate: (value: T, index: number, obj: T[]) => boolean): T {
        const result = this.SingleOrDefault(predicate);
        if( !result )
            throw new Error('Array does not contains items for specified search criteria');
        return result;
    }
}

if (!Array.prototype.SingleOrDefault) {
    Array.prototype.SingleOrDefault = function<T>(predicate: (value: T, index: number, obj: T[]) => boolean): T | null {
        const result = this.filter(predicate);
        if( result.length > 1 )
            throw new Error('Array contains more then one item for specified search criteria');
        return result.length === 0 ? null : result[0];
    }
}

if (!Array.prototype.Where) {
    Array.prototype.Where = function<T>(predicate: (value: T, index: number, obj: T[]) => boolean): T[] {
        return this.filter(predicate);
    }
}

if (!Array.prototype.Count) {
    Array.prototype.Count = function<T>(predicate: (value: T, index: number, obj: T[]) => boolean): number {
        return this.filter(predicate).length;
    }
}

if (!Array.prototype.IndexOf) {
    Array.prototype.IndexOf = function<T>(predicate: (value: T, index: number, obj: T[]) => boolean): number {
        for(let i=0; i<this.length; i++) {
            if( predicate(this[i], i, this) )
                return i;
        }

        return -1;
    }
}

if (!Array.prototype.OrderBy) {
    Array.prototype.OrderBy = function<T>(selector: (value: T) => any): T[] {
        return this.sort( (a, b) => {
            const vala = selector(a);
            const valb = selector(b);

            if( vala === null ) return -1;
            if( valb === null ) return 1;

            return vala > valb ? 1 : vala < valb ? -1 : 0;
        } );
    }
}

if (!Array.prototype.OrderByDescending) {
    Array.prototype.OrderByDescending = function<T>(selector: (value: T) => any): T[] {
        return this.sort( (a, b) => {
            const vala = selector(a);
            const valb = selector(b);

            if( vala === null ) return 1;
            if( valb === null ) return -1;

            return vala > valb ? -1 : vala < valb ? 1 : 0;
        } );
    }
}

if (!Array.prototype.GroupBy) {
    Array.prototype.GroupBy = function<T, Tk>(selector: (value: T) => Tk): Map<Tk, T[]> {
        const map = new Map<Tk, T[]>();

        this.forEach((item) => {
            const key = selector(item);
            const values = map.get(key);
            if (!values) {
                map.set(key, [item]);
            } else {
                values.push(item);
            }
        });

        return map;
    }
}


if (!Array.prototype.Min) {
    Array.prototype.Min = function<T>(selector: (value: T) => any): T {
        let minVal = null;
        let minIndex = -1;

        for(let i=0; i<this.length; i++)
        {
            const val = selector(this[i]);
            if (val < minVal || !minVal) {
                minVal = val;
                minIndex = i;
            }
        }

        return minIndex === -1 ? null : this[minIndex];
    }
}

if (!Array.prototype.Max) {
    Array.prototype.Max = function<T>(selector: (value: T) => any): T {
        let val = null;
        let index = -1;

        for(let i=0; i<this.length; i++)
        {
            const val1 = selector(this[i]);
            if (val1 > val || !val) {
                val = val1;
                index = i;
            }
        }

        return index === -1 ? null : this[index];
    }
}

if (!Array.prototype.Remove) {
    Array.prototype.Remove = function<T>(value: T): boolean {
        let result = false;

        while( true ) {
            const index = this.indexOf(value);
            if (index > -1) {
                this.splice(index, 1);
                result = true;
            }
            else
                break;
        }

        return result;
    }
}

if (!Array.prototype.RemoveRange) {
    Array.prototype.RemoveRange = function<T>(values: T[]): void {
        values.forEach( x => {
            this.Remove(x);
        });
    }
}

if (!Array.prototype.RemoveRangeWhere) {
    Array.prototype.RemoveRangeWhere = function<T>(predicate: (value: T, index: number, obj: T[]) => boolean): void {
        this.RemoveRange( this.Where(predicate) );
    }
}

if (!Array.prototype.Add) {
    Array.prototype.Add = function<T>(value: T): void {
        this.push(value);
    }
}

if (!Array.prototype.Distinct) {
    Array.prototype.Distinct = function<T>(keySelector: (value: T, index: number, obj: T[]) => string | number): T[] {
        const uniqueDict = {};

        for(let i=0; i<this.length; i++)
        {
            const keyValue = keySelector(this[i], i, this);
            uniqueDict[`${keyValue ? keyValue.toString() : '...'}`] = this[i];
        }

        return Object.keys(uniqueDict).map(x => uniqueDict[x]);
    }
}

if (!Array.prototype.Sum) {
    Array.prototype.Sum = function<T>(selector: (value: T) => number): number {
        let result = 0;
        this.forEach(x => result += selector(x));
        return result;
    }
}

if (!Array.prototype.DeepClone) {
    Array.prototype.DeepClone = function<T>(): T[] {
        const result = instanceToInstance(this);
        return result;
    }
}

/* eslint-enable no-extend-native */
