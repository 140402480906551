import React, {Component} from "react";
import {Button} from "@mui/material";

interface IState {
}

interface IProps{
}

export class LogoutPage extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state ={
        };
    }

    async componentDidMount() {

    }


    render() {
        return (
            <div>
                <Button
                    // onClick={() => MsalService.Login()}
                    color="inherit"
                >
                    Login
                </Button>
            </div>
        )
    }
}

