import React, {Component} from "react";
import {Board} from "./Board/Board";
import {BigLoading} from "./Reusable/BigLoading";
import {BoardCtlr} from "./Board/BoardCtlr";
import {ColumnList} from "../Models/ColumnList";
import BoardSelectDialog, {BoardSelectDialogInstance} from "./BoardSelectDialog/BoardSelectDialog";
import EditCardDialog from "./EditCardDialog/EditCardDialog";
import EditBoardDialog, {EditBoardDialogInstance} from "./EditBoardDialog/EditBoardDialog";
import {BoardModel} from "../Models/BoardModel";

interface IState {
    IsLoading: boolean,
}

interface IProps{
}

export class HomePage extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state ={
            IsLoading: true
        };
    }

    async componentDidMount() {
        this.setState({IsLoading: true});

        try {
            //await new Promise((resolve) => setTimeout(resolve, 1000));
            await BoardCtlr.Instance.Load();
        } finally {
            await this.setState({IsLoading: false});
            window.scrollTo(0, 0); // sometimes scrolled to the middle ?!
        }

        if( !BoardCtlr.Instance.Board ) {
            EditBoardDialogInstance.Open( new BoardModel() );
        }
    }

    render() {
        if( this.state.IsLoading )
            return <BigLoading showLoadingMessages/>;

        return (
            <>
                <Board/>
            </>
        );
    }

}

