import {T} from "../Components/Reusable/IntlProviderEx";

export class ValidationResult {
    public error : boolean;

    public helperText : string;

    constructor(error : boolean, helperText : string) {
        this.error = error;
        this.helperText = helperText;
    }
}

export type ValidationRule = (value: any) => string;

export class Validation {
    //
    // Returns empty object if there is no error
    //
    public static ComposeRules(value: any, ...rules: ValidationRule[]): (ValidationResult | any) {
        for (const rule of rules) {
            const error = rule(value);
            if (error && error.length > 0)
                return new ValidationResult(true, error);
        }

        return {};
    }

    public static Required() : ValidationRule {
        return (value: any) => {
            if (!value && value !== 0)
                return T("validation.required");

            if (typeof value === "string" && value.trim().length === 0)
                return T("validation.required");

            return null;
        }
    }

    public static NumPositive(): ValidationRule {
        return (value: number) => {
            if (!value || value <= 0)
                return T("validation.positiveRequired");

            return null;
        }
    }

    public static NumMin(min: number): ValidationRule {
        return (value: number) => {
            if (value < min)
                return T("validation.minIs") + " " + min;

            return null;
        }
    }

    public static NumMax(max: number): ValidationRule {
        return (value: number) => {
            if (value > max)
                return T("validation.maxIs") + " " + max;

            return null;
        }
    }

    public static NumExact(val: number, message?: string): ValidationRule {
        return (value: number) => {
            if (value !== val)
                return message || T("validation.mustBe") + " " + val;

            return null;
        }
    }

    public static MaxLength(max: number, message?: string): ValidationRule {
        return (value: string) => {
            if (value && value.length > max)
                return message || T("validation.maxLengthIs") + " " + max;

            return null;
        }
    }

    public static Email() : ValidationRule {
        return (value: string) => {
            if( !value || value.length === 0 ) return null;

            if( !value.match('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$') )
                return T("validation.emailRequired");

            return null;
        };
    }
}