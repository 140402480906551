export class DevOpsQueriesResponse {
    public count: number;

    public value: DevOpsQuery[];
}

export class DevOpsQuery {
    public id: string;

    public name: string;

    // e.g. "My Queries/Followed work items"
    public path: string;

    public hasChildren: boolean;

    public isFolder: boolean;

    public children: DevOpsQuery[];
}
