import {Validation, ValidationResult, ValidationRule} from "./Validation";

export default class ValidationMgr {
    private validationResults: ValidationResult[] = [];
    private _errorsDisplayed: boolean = false;

    get HasError(): boolean {
        const hasError = this.validationResults.Any(x => x.error);
        return hasError;
    }

    public Validate = (value: any, ...rules: ValidationRule[]): (ValidationResult | any) => {
        const result = Validation.ComposeRules(value, ...rules);
        this.validationResults.Add(result);

        return this._errorsDisplayed ? result : null;
    };

    public StartValidation() {
        this.validationResults = [];
    }

    public EndValidation() : boolean {
        return this.HasError;
    }

    get ErrorsDisplayed() {
        return this._errorsDisplayed;
    }

    set ErrorsDisplayed(value: boolean) {
        this._errorsDisplayed = value;
    }
}