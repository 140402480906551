import {IDevOpsClient} from "./IDevOpsClient";
import {BaseApi, FetchOptions} from "../../Tools/BaseApi";
import {deserialize} from "class-transformer";
import {DevOpsQueriesResponse, DevOpsQuery} from "./DevOpsQueriesResponse";
import {DevOpsConnectorModel} from "../../Models/DevOpsConnectorModel";
import {NameId} from "../../Tools/NameId";
import {DevOpsWorkItem} from "../../Models/DevOpsWorkItem";
import {BoardCtlr} from "../../Components/Board/BoardCtlr";
import {DevOpsWiqlResponse} from "../../Models/DevOpsWiql";
import {DevOpsWorkitemsBatchRequest, DevOpsWorkitemsBatchResponse} from "../../Models/DevOpsWorkitemsBatch";
import {DevOpsRestApi} from "../../Tools/DevOpsRestApi";

export class DevOpsClient extends DevOpsRestApi implements IDevOpsClient {
    constructor(connector: DevOpsConnectorModel) {
        super(connector);
    }

    // public async GetQueries() : Promise<NameId<string>[]> {
    //     const organization = this._connector.Organization;
    //     const project = this._connector.Project || 'the-board';
    //
    //     let queryStr = `${this._devOpsUrl}/${organization}/${project}/_apis/wit/queries?api-version=6.0&$depth=2`;
    //
    //     const response = await this.getText(queryStr);
    //     const result = deserialize(DevOpsQueriesResponse, response);
    //
    //     const queries = DevOpsClient.FlatQueries(result.value);
    //
    //     return queries.map( x => {
    //         return new NameId<string>(x.id, x.path);
    //     } );
    // }

    // public async GetOrganizations() : Promise<string[]> {
    //     let me = await  this.GetMyProfile();
    //
    //     let queryStr = `${this._vsOnlineUrl}/_apis/accounts?memberId=${me.publicAlias}&api-version=6.0`;
    //     const response = await this.getJson<any>(queryStr);
    //
    //     return response.value.Select((x : any) => x.accountName);
    // }
    //
    // public async GetProjects(organization: string) : Promise<string[]> {
    //     let me = await  this.GetMyProfile();
    //
    //     let queryStr = `${this._devOpsUrl}/${organization}/_apis/projects?api-version=5.1`;
    //     const response = await this.getJson<any>(queryStr);
    //
    //     return response.value.Select((x : any) => x.name);
    // }
    //
    // private async GetMyProfile() : Promise<any> {
    //     // All organizations access token is required
    //     let queryStr = `${this._vsOnlineUrl}/_apis/profile/profiles/me?api-version=5.1`;
    //     const response = await this.getJson(queryStr);
    //
    //     return response;
    // }

    // private static FlatQueries(tree: DevOpsQuery[]) : DevOpsQuery[] {
    //     return tree.reduce(function(acc, o) {
    //         if(!o.isFolder)
    //             acc.push(o);
    //         if(o.hasChildren && o.children)
    //             acc = acc.concat(DevOpsClient.FlatQueries(o.children));
    //         return acc;
    //     }, []);
    // }

    protected override async GetFetchOptions() : Promise<FetchOptions> {
        return new FetchOptions( {BasicAuthToken: this._connector.Pat} );
    }
}
