import * as React from "react";
import {BrowserRouter, BrowserRouterProps, Route, RouteComponentProps, Switch, withRouter} from "react-router-dom";
import {AppCtlr} from "../../AppCtlr";
import ErrorBoundary from "../../Components/Reusable/ErrorBoundary";
import {NotFoundPage} from "../NotFoundPage";
import {LogoutPage} from "../../Components/LogoutPage/LogoutPage";
import {RouteNames} from "./RouteNames";
import {HomePage} from "../../Components/HomePage";
import MiniDrawerLayout from "../../Components/MiniDrawerLayout";
import EditBoardDialog from "../../Components/EditBoardDialog/EditBoardDialog";
import BoardSelectDialog from "../../Components/BoardSelectDialog/BoardSelectDialog";
import EditCardDialog from "../../Components/EditCardDialog/EditCardDialog";
import ColumnsConfigDialog from "../../Components/ColumnsConfigDialog/ColumnsConfigDialog";
import {BoardCtlr} from "../../Components/Board/BoardCtlr";

class ScrollToTop extends React.Component<RouteComponentProps> {
    constructor(props: RouteComponentProps) {
        super(props);
    }
    public componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0,0);
        }
    }
    public render() {
        return this.props.children;
    }
}

const RoutedScrollToTop = withRouter(ScrollToTop);

interface IProps extends BrowserRouterProps {
}

interface IState {
}

export class AppRouter extends React.Component<IProps, IState> {
    private readonly routerRef: React.RefObject<any> = React.createRef();

    constructor(props: IProps) {
        super(props);
        this.routerRef = React.createRef();

        AppCtlr.Instance.PropertyChanged.Listen(args => {
            args.ForProperties([
            ], () => this.forceUpdate())
        });

        BoardCtlr.Instance.BoardChangedEvent.Listen(this);

        this.state = {
        };
    }

    public componentDidMount(): void {
        AppCtlr.Instance.Browser.Init(this.routerRef);
    }

    private RenderSignedOut() {
        return  (
            <LogoutPage/>
        );
    }

    private RenderSignedIn() {
        return (
            <MiniDrawerLayout>
                <ErrorBoundary>
                    <Switch>
                        <Route exact path={RouteNames.Home}>
                            <HomePage/>
                        </Route>
                        <Route exact path={RouteNames.Logout}
                               render={(props) => <LogoutPage/>}>
                        </Route>
                        <Route component={NotFoundPage}/>
                    </Switch>

                    <EditBoardDialog/>
                    <BoardSelectDialog/>
                    <EditCardDialog />
                    <ColumnsConfigDialog />
                </ErrorBoundary>
            </MiniDrawerLayout>
        );
    }

    public render() {
        return (
            <BrowserRouter ref={this.routerRef} basename={process.env.PUBLIC_URL} {...this.props}>
                <RoutedScrollToTop>
                    { this.RenderSignedIn() }
                    {/*{*/}
                    {/*    AppCtlr.Instance.IsSignedIn*/}
                    {/*        ?   this.RenderSignedIn()*/}
                    {/*        :   this.RenderSignedOut()*/}
                    {/*}*/}
                </RoutedScrollToTop>
            </BrowserRouter>
        );
    }
}
