import React from "react";
import {BoardCtlr} from "../Board/BoardCtlr";
import {useTheme} from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import {Autocomplete, Button, Dialog, Grid, InputLabel, TextField, Theme, ToggleButton, ToggleButtonGroup} from "@mui/material";
import ValidationMgr from "../../Tools/ValidationMgr";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "./EditCardDialog.scss"
import {CardModel} from "../../Models/CardModel";
import CheckIcon from '@mui/icons-material/Check';
import {BaseComponent} from "../Reusable/BaseComponent";
import {MappingType} from "../../Models/ColumnModel";

interface IState {
    IsOpen: boolean;
    Title: string;
    Url: string;
    Body: string;
    Color: string;
    Tags: string[];

    InitialCard: CardModel;
}

interface IProps{
    IsFullScreen: boolean;
}

class EditCardDialogInternal extends BaseComponent<IProps, IState> {
    private _validationMgr: ValidationMgr = new ValidationMgr();
    public static _instance: EditCardDialogInternal;
    private _cleanState: IState = {
        IsOpen: false,
        InitialCard: null,
        Url: '',
        Title: '',
        Body: '',
        Color: '',
        Tags: []
    };

    constructor(props: IProps) {
        super(props);
        this.state = { ...this._cleanState };
        EditCardDialogInternal._instance = this;
    }

    private Close = () => {
        this.setState( { ...this._cleanState} );
    }

    private OnSaveClick = () => {
        if( this._validationMgr.HasError )
        {
            this._validationMgr.ErrorsDisplayed = true;
            this.forceUpdate();
            return;
        }

        const isNew = !this.state.InitialCard;
        if( isNew ) {
            BoardCtlr.Instance.AddCard(
                this.state.Title, this.state.Url, this.state.Body, this.state.Color, this.state.Tags);
        }
        else {
            BoardCtlr.Instance.SaveCard(
                this.state.InitialCard.Id,
                this.state.Title, this.state.Url, this.state.Body, this.state.Color, this.state.Tags);
        }

        this.Close();
    }

    render() {
        console.log("EditCardDialogInternal.render");

        this._validationMgr.StartValidation();

        const isOpen = this.state.IsOpen;
        const isNew = !this.state.InitialCard;

        let titleOrBodyRequired = () : string => {
            if( !this.state.Title && !this.state.Body )
                return "Title or Body is required"
            return null;
        };

        const tagsOptions = BoardCtlr.Instance.GetCardTags();

        return (
            isOpen &&
            <Dialog
                fullScreen={this.props.IsFullScreen}
                open={true}
                aria-labelledby="responsive-dialog-title"
                classes={{paper: "edit-card-dialog nscrollable"}}
            >
                <DialogTitle id="responsive-dialog-title">
                    {isNew ? "Add Card" : "Edit Card"}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={10}>
                            <TextField
                                autoFocus
                                margin="normal"
                                fullWidth
                                { ...this.WithLabel("Title") }
                                { ...this.BindState("Title") }
                                {...this._validationMgr.Validate(this.state.Title, titleOrBodyRequired )}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                {...this.WithLabel("Url")}
                                { ...this.BindState("Url") }
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                multiline
                                {...this.WithLabel("Body")}
                                { ...this.BindState("Body") }
                                {...this._validationMgr.Validate(this.state.Body, titleOrBodyRequired )}
                            />
                            <Autocomplete
                                disablePortal
                                options={tagsOptions}
                                multiple
                                freeSolo
                                clearOnBlur={true}
                                renderInput={(params) => <TextField
                                    onBlur={(e) => {
                                        if( e.target.value?.length > 0)
                                            this.setState( {Tags: [ ...this.state.Tags, e.target.value]} );
                                    }}
                                    {...params}
                                    margin={"normal"}
                                    { ...this.WithLabel("Tags")}
                                />}
                                value={this.state.Tags || []}
                                onChange={(event, newValue: any) => {
                                    this.setState( {Tags: newValue} );
                                }}
                            />

                        </Grid>
                        <Grid item xs={2} alignItems={"end"} className={"color-buttons"}>
                            <InputLabel color={"primary"}>Color</InputLabel>
                            <ToggleButtonGroup
                                orientation="vertical"
                                exclusive
                            >
                                {
                                    ["#f44336", "#3f51b5", "#2196f3", "#009688", "#ffeb3b", "#ffc107"].map( x => {
                                        return (
                                            <ToggleButton key={x} value={x} className={"color-btn"} style={{backgroundColor: x}}
                                                onClick={ () => {
                                                    this.setState( { Color: x});
                                                }}
                                            >
                                                { this.state.Color === x ? <CheckIcon style={{color: x, filter: "invert(100%)"}}/> : null }
                                            </ToggleButton>
                                        );
                                    })
                                }
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.Close}>
                        Cancel
                    </Button>
                    <Button onClick={this.OnSaveClick} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default function EditCardDialog() {
    const theme : Theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <EditCardDialogInternal IsFullScreen={fullScreen}/>
    );
}

export class EditCardDialogInstance {
    public static OpenNewCard() {
        EditCardDialogInternal._instance.setState({
            IsOpen: true,
            InitialCard: null,
            Url: '',
            Title: '',
            Body: '',
            Color: ''
        });
    }

    public static OpenEditCard(card: CardModel) {
        EditCardDialogInternal._instance.setState({
            IsOpen: true,
            InitialCard: card,
            Url: card.TitleUrl,
            Title: card.Title,
            Body: card.Body,
            Color: card.Color,
            Tags: card.Tags
        });
    }
}
