import {NameId} from "../Tools/NameId";

export class DevOpsConnectorModel {
    constructor(data?: Partial<DevOpsConnectorModel>) {
        Object.assign(this, data);
    }

    public IsConnected: boolean;

    public Organization: string;

    // TODO
    public Project: string;

    public Query: NameId<string>;

    public Pat: string;
}
