import {Exclude} from "class-transformer";
import {Rectangle} from "../Tools/Drawing";
import {DevOpsConnectorModel} from "./DevOpsConnectorModel";

export class BoardModel {
    constructor(data?: Partial<BoardModel>) {
        Object.assign(this, data);
    }

    public Id: number;

    public Name: string;

    public Description: string;

    public IsActive: boolean;

    public Rect: Rectangle = new Rectangle(0, 0, 2560, 1920);

    public DevOpsConnector: DevOpsConnectorModel;
}
