import { Decimal } from "decimal.js";

export function ToMoneyString(amount: number | Decimal, withCurrency: boolean = true) {
	let num: number;
	let formatter;

	if (typeof amount == "number") {
		num = (amount as number) || 0;
	} else {
		num = ((amount as Decimal) || new Decimal(0)).toNumber();
	}

	if (withCurrency) {
		formatter = new Intl.NumberFormat("fr", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			style: "currency",
			currency: "EUR",
		});
	} else {
		formatter = new Intl.NumberFormat("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
	}

	// Customize number parts (decimal sign, group separator etc)
	let strNumber = formatter
		.formatToParts(num)
		.map(({ type, value }) => {
			switch (type) {
				case "decimal":
					return ".";
				case "group":
					return " ";
				default:
					return value;
			}
		})
		.reduce((string, part) => string + part);

	return strNumber;
}

export function RandomInteger(min : number, max: number) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
