import {deserialize, serialize} from "class-transformer";
import {ColumnModel, MappingType} from "./ColumnModel";
import {BaseCtlr} from "../Tools/BaseCtlr";
import {ColumnsConfig} from "./ColumnsConfig";
import {BoardModel} from "./BoardModel";

export class ColumnList extends BaseCtlr {
    private static _instance: ColumnList = new ColumnList();
    private _columns: ColumnModel[] = [];
    private _columnsConfig: ColumnsConfig = new ColumnsConfig();

    static get Instance(): ColumnList {
        return this._instance;
    }

    public Load(board: BoardModel) {
        const columns = [];

        for (var i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.startsWith(this.GetColumnKeyPrefix(board.Id))) {
                const item = deserialize(ColumnModel, localStorage[key]);
                columns.push(item);
            }
        }

        const colConfig = localStorage[this.GetColumnsConfigKeyPrefix(board.Id)];
        if( colConfig )
            this._columnsConfig = deserialize(ColumnsConfig, colConfig);
        this.Columns = columns.OrderBy(x => x.Order);
    }

    public Save(boardId: number, columns: ColumnModel[], mapTo: MappingType, syncWithDevOps: boolean) {
        for (var i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.startsWith(this.GetColumnKeyPrefix(boardId))) {
                localStorage.removeItem(key);
            }
        }

        columns.forEach( x => {
            localStorage[this.GetColumnKeyPrefix(boardId) + x.Title] = serialize( x );
        });

        const columnsConfig = new ColumnsConfig( {MapTo: mapTo, SyncToDevOps: syncWithDevOps} )
        localStorage[this.GetColumnsConfigKeyPrefix(boardId)] = serialize( columnsConfig );
        this._columnsConfig = columnsConfig;

        this.Columns = columns.OrderBy(x => x.Order);
    }

    public get Columns() : ColumnModel[] {
        return this._columns;
    }

    public set Columns(item: ColumnModel[]) {
        this.SetWithNotification("_columns", "Columns", item);
    }

    public get ColumnsConfig() : ColumnsConfig {
        return this._columnsConfig;
    }

    //public Columns: ColumnModel[];
    // = [
    //     new ColumnModel({ Order: 1, Width: 22, Title: "DevR"}),
    //     new ColumnModel({ Order: 2, Width: 12, Title: "Dev"}),
    //     new ColumnModel({ Order: 3, Width: 17, Title: "TestR"}),
    //     new ColumnModel({ Order: 4, Width: 12, Title: "Testing"}),
    //     new ColumnModel({ Order: 5, Width: 20, Title: "Tested"}),
    //     new ColumnModel({ Order: 6, Width: 17, Title: "On Hold"}),
    // ];

    // public DevOpsStateMapping : any[] = [
    //     { Columns: ["DevR"], States: ["New"] },
    //     { Columns: ["Dev"], States: ["Active"] },
    //     { Columns: ["TestR", "Testing"], States: ["Resolved"] },
    //     { Columns: ["Tested", "On Hold"], States: ["Closed"] },
    // ];

    public GetColumnMapping(columnTitle: string) : string {
        const col = this.Columns.FirstOrDefault(x => x.Title === columnTitle);

        if( col && col.Mapping && col.Mapping.length > 0 ) {
            return col.Mapping[0];
        }

        return null;
    }

    public GetColumnByMapping(mapping: string) : ColumnModel {
        const col = this.Columns.FirstOrDefault(x => x.Mapping.Any(m => m === mapping));
        return col;
    }

    private GetColumnsConfigKeyPrefix(boardId: number) : string {
        return "#ColumnsConfig_" + boardId;
    }

    private GetColumnKeyPrefix(boardId: number) : string {
        return "#Column_" + boardId + "_";
    }
}
