import React, {Component} from "react";
import {Typography} from "@mui/material";
import "./Column.scss";
import {PropertyChangedEventArgs} from "../../Tools/PropertyChangedEvent";
import {BoardCtlr} from "./BoardCtlr";
import {ColumnModel} from "../../Models/ColumnModel";

interface IState {
    IsLoading: boolean,
    Data?: any
}

interface IProps{
    Column: ColumnModel
}

export class Column extends Component<IProps, IState> {
    // private _validationMgr: ValidationMgr = new ValidationMgr();
    private readonly _colRef: React.RefObject<any>;

    constructor(props: IProps) {
        super(props);
        this._colRef = React.createRef();

        this.state ={
            IsLoading: false
        };

        BoardCtlr.Instance.PropertyChanged.ListenProps(["FocusedColumn"], this.OnFocusedColumnChanged);
    }

    async componentDidMount() {
        // this.setState( { IsLoading: true} );
        //
        // let data;
        //
        // try {
        //     data = await Api.GetData();
        // }
        // finally {
        //     this.setState( { Data: data, IsLoading: false } );
        // }
    }

    componentWillUnmount() {
        BoardCtlr.Instance.PropertyChanged.Unlisten(this.OnFocusedColumnChanged);
    }


    render() {
        // this._validationMgr.StartValidation();
        console.log("Column.render " + this.props.Column.Title);

        const col = this.props.Column;

        const classes = "col" +
            (BoardCtlr.Instance.FocusedColumn === col ? " focused" : "");

        return (
            <div className={classes}
                 style={{width: col.Width + "%"}}
                 ref={this._colRef}
            >
                <div className={"col-title"} style={{height: ColumnModel.TitleHeight}}>
                    <Typography variant={'h6'}>
                        {col.Title}
                    </Typography>
                </div>
            </div>
        )
    }

    private OnFocusedColumnChanged = (args: PropertyChangedEventArgs) => {
        if( args.NewValue === this.props.Column || args.OldValue === this.props.Column)
            this.forceUpdate();
    }
}

