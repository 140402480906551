// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import  ConfigManager from './ConfigManager'
import { IntlProviderEx } from './Components/Reusable/IntlProviderEx';
import {AppCtlr} from "./AppCtlr";
import { ThemeProvider } from '@mui/material/styles';
import {AppTheme} from "./Components/AppTheme";
import * as SDK from "azure-devops-extension-sdk";

export function HideRootLoading() {
    // Hide root loading indicator
    document.getElementById("root-loading").style.display = "none";
}

async function start() : Promise<void>
{
    try
    {
        try
        {
            //
            //await ConfigManager.Load();

            // Login scenarious:
            // 1) Landing to logout page - no autologin, Login button is shown
            // 2) Landing to non-logout page - autologin is executed
            //await MsalService.Initialize( !AppCtlr.Instance.Browser.IsLogoutPage );

            await AppCtlr.Instance.Initialize();
            //
            // const getApiConfigPromise = Api.GetApiConfig();
            // const getCurrentUserPromise = Api.GetUserProfile();
            //
            // await getApiConfigPromise;
            // await getCurrentUserPromise;

            if( AppCtlr.Instance.IsDevOpsExtension) {
                await SDK.init();
            }
        }
        finally
        {
            HideRootLoading();
        }
    }
    catch(error: any) {
        const message = error.url
            ? `Fetching url '${error.url}' failed, the reason ${error.toString()}`
            : error.toString();

        ReactDOM.render(
            <div>The error while initializing the application: {message}</div>,
            document.getElementById('root') as HTMLElement);
        throw error;
    }

    ReactDOM.render(
        <IntlProviderEx>
            <ThemeProvider theme={AppTheme}>
                <App/>
            </ThemeProvider>
        </IntlProviderEx>,
        document.getElementById('root') as HTMLElement
    );
}

// noinspection JSIgnoredPromiseFromCall
start();
