export class ColumnModel {
    static TitleHeight: number = 48;

    constructor(data?: Partial<ColumnModel>) {
        Object.assign(this, data);
    }

    // The width in %
    public Width: number;

    public Title: string;

    public Order: number;

    public Mapping: string[];
}

export enum MappingType {
    State = "State",
    Area = "Area"
}
