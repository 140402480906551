import React from "react";
import {Autocomplete, CircularProgress, TextField, Tooltip} from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';

export class AutocompleteHelper {
    public static RenderAutocomplete<TElement>(label: string, helperText: React.ReactNode,
                                         isDisabled: boolean, options: TElement[], isLoading: boolean,
                                         value: TElement, onChange: (
            event: React.SyntheticEvent,
            value: TElement | null
        ) => void, getOptionLabel?: (option: TElement) => string, tooltip?: React.ReactNode)
    {
        return (
            <Autocomplete
                disablePortal
                disableClearable={false}
                disabled={isDisabled}
                options={ options }
                loading={ isLoading }
                getOptionLabel={ getOptionLabel }
                renderInput={(params) =>
                    <TextField
                        {...params}
                        margin={"normal"}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {isLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        helperText={<span>{helperText}</span>}
                        label={ tooltip
                            ? <>
                            <Tooltip classes={{tooltip: "wide-tooltip"}} title={tooltip}>
                                <HelpIcon />
                            </Tooltip>
                                { label }
                            </>
                            : label }
                        placeholder={ label }
                    />}
                value={value || null}
                onChange={async (event, newValue) => {
                    onChange(event, newValue as TElement);
                }}
            />
        );
    }

    private static WithLabel = (label: string) => ({label, placeholder: label});
}
