export class DevOpsWorkItem {
    constructor(data?: Partial<DevOpsWorkItem>) {
        Object.assign(this, data);
    }

    public Id: number;

    public Title: string;

    public Url: string;

    public WorkItemType: string;

    public State: string;

    public Area: string;

    public Tags: string[];
}
