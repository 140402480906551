import * as React from "react";
import {Icon, IconButton, Snackbar, SnackbarContent} from "@mui/material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {SnackbarCloseReason} from "@mui/material/Snackbar/Snackbar";
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
}

interface IState {
    open: boolean;
    message: string;
    variant: 'success' | 'warning' | 'error' | 'info';
    content?: () => React.ReactElement<any>;
    autoHideDuration?: number;
    vertical?: 'top' | 'bottom'
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class MessageBox extends React.Component<IProps, IState> {

    private static _instance: MessageBox;

    constructor(props: IProps) {
        super(props);

        this.state = { open: false, message: '', variant: 'info', vertical: "bottom" };
        MessageBox._instance = this;
    }

    public static Info(message: string, content?: () => React.ReactElement<any>, autoHideDuration?: number, vertical?: 'top' | 'bottom') {
        MessageBox.Open(message, "info", content, autoHideDuration, vertical);
    }

    public static Error(message: string) {
        // Log.error(message);
        MessageBox.Open(message, "error");
    }

    public static Warning(message: string) {
        MessageBox.Open(message, "warning");
    }

    public static Success(message: string) {
        MessageBox.Open(message, "success");
    }

    public static Open(message: string
        , variant: 'success' | 'warning' | 'error' | 'info'
        , content?: () => React.ReactElement<any>
        , autoHideDuration?: number
        , vertical?: 'top' | 'bottom') {
        MessageBox._instance.setState({
            open: true,
            message,
            variant,
            content,
            autoHideDuration,
            vertical: vertical ? vertical : 'bottom'
        });
    }

    public static Close() {
        if( MessageBox._instance ) {
            MessageBox._instance.setState({
                open: false
            });
        }
    }

    public render() {
        let content = <IconButton
            aria-label="Close"
            color="inherit"
            onClick={() => this.setState({open: false})}
            size={"small"}
        >
            <CloseIcon fontSize={"small"}/>
        </IconButton>;

        if( this.state.content )
            content = <>
                {this.state.content()}
                {content}
            </>;

        return (
            <Snackbar open={this.state.open}
                      onClose={this.handleClose}
                      anchorOrigin={{vertical: this.state.vertical, horizontal: "center"}}>
                <Alert onClose={this.handleClose} severity={this.state.variant} sx={{ width: '100%' }} action={content}>
                    { this.state.message }
                </Alert>
            </Snackbar>
        );
    }

    private handleClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ open: false });
    };
}
