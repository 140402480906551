import React, { Component } from "react";
import { IntlProvider } from "react-intl";
// import Api from "../../Api";
// import labels from "../../Translations/labels.json";

const CURRENT_LANGUAGE = "tra-current-language";

const Context = React.createContext<IState | undefined>(undefined);

interface IState {
	CurrentLang: string;
	SwitchLanguageHandler: any;
}

interface IProps {}

class IntlProviderEx extends Component<IProps, IState> {
	static _messages: any = { En: [], Fr: []};
	static _currentLang: string = "En";

	constructor(props: IProps) {
		super(props);

		this.state = { CurrentLang: "En", SwitchLanguageHandler: null };

		//
		// const preferredLanguage = localStorage.getItem(CURRENT_LANGUAGE) || Api.CurrentUser.PreferredLanguage || "En";
		// Api.CurrentLanguage = preferredLanguage;
		// this.state = {
		// 	CurrentLang: preferredLanguage,
		// 	SwitchLanguageHandler: this.switchLanguageHandler,
		// };
		//
		// for(const prop in labels) {
		// 	IntlProviderEx._messages.En[prop] = labels[prop].En || labels[prop].en;
		// 	IntlProviderEx._messages.Fr[prop] = labels[prop].Fr || labels[prop].fr;
		// }
		//
		// IntlProviderEx._currentLang = this.state.CurrentLang;
	}

	switchLanguageHandler = () => {
		// let newLang = this.state.CurrentLang === "En" ? "Fr" : "En";
		// this.setState({ CurrentLang: newLang });
		// localStorage.setItem(CURRENT_LANGUAGE, newLang);
		// Api.CurrentLanguage = newLang;
		// IntlProviderEx._currentLang = newLang;
	};

	render() {
		const { children } = this.props;
		const { CurrentLang } = this.state;
		return (
			<Context.Provider value={this.state}>
				<IntlProvider locale={CurrentLang} messages={IntlProviderEx._messages[CurrentLang]}>
					{children}
				</IntlProvider>
			</Context.Provider>
		);
	}
}

export { IntlProviderEx, Context as IntlContext };

export function T(id: string) {
	const lang = IntlProviderEx._currentLang;
	const result = IntlProviderEx._messages[lang][id] || id;
	 // console.log(`T(${id}) = ${result}`);
	return result;
}

export const LanguageEng = "En";
export const LanguageFr = "Fr";
