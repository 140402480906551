export class NameId<TId> {
    constructor(id: TId, name: string) {
        this.Name = name;
        this.Id = id;
    }

    public Id: TId;

    public Name: string;
}
