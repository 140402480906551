import {ICardsStorage} from "./ICardsStorage";
import {CardModel, CardType} from "../Models/CardModel";
import {BoardModel} from "../Models/BoardModel";
import {ColumnModel} from "../Models/ColumnModel";
import {deserialize, serialize} from "class-transformer";

export class LocalCardsStorage implements ICardsStorage {
    private _board: BoardModel;

    constructor(board: BoardModel) {
        this._board = board;
    }

    LoadCards(): Promise<CardModel[]> {
        const localCards = [];

        for (var i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.startsWith( this.GetCardKeyPrefix() )) {
                const card = deserialize(CardModel, localStorage[key]);
                localCards.push(card);
            }
        }

        const result = localCards;

        return Promise.resolve(result);
    }

    async AddCard(card: CardModel): Promise<void> {
        await this.SaveCard(card);
        return Promise.resolve(undefined);
    }

    async DeleteCard(card: CardModel): Promise<void> {
        if( card.CardType !== CardType.Local )
            throw new Error(`Cannot delete card of ${card.CardType} type`);

        card.IsDeleted = true;
        await this.SaveCard(card);

        return Promise.resolve(undefined);
    }

    async RestoreCard(card: CardModel): Promise<void> {
        card.IsDeleted = false;
        await this.SaveCard(card);
        return Promise.resolve(undefined);
    }

    SaveCard(card: CardModel): Promise<void> {
        localStorage[ this.GetCardKeyPrefix() + card.Id] = serialize(card);
        return Promise.resolve(undefined);
    }

    async SaveCardPosition(card: CardModel, posX: number, posY: number, column: ColumnModel): Promise<void> {
        card.PosX = posX;
        card.PosY = posY;
        await this.SaveCard(card);
        return Promise.resolve(undefined);
    }

    async SaveCardSize(card: CardModel, width: number, height: number): Promise<void> {
        card.Width = width;
        card.Height = height;
        await this.SaveCard(card);
        return Promise.resolve(undefined);
    }

    private GetCardKeyPrefix() : string {
        return "#Card_" + this._board.Id + "_";
    }
}
