import * as React from "react";
import Log from "../../Tools/Logger";

class ErrorBoundary extends React.Component {
    public componentDidCatch(error: Error, info: React.ErrorInfo) {
        Log.error("Unhandled error in the component.", "Error: " + error, "ErrorInfo: " + JSON.stringify(info));
    }
    public render() {
        return this.props.children;
    }
}
export default ErrorBoundary;