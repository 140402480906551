import React from "react";
import {Checkbox, FormControlLabel, Grid, TextField, Tooltip, Typography} from "@mui/material";
import {Validation} from "../../Tools/Validation";
import DevOpsPatHelp from "../../Assets/Images/DevOpsPatHelp.png";
import {NameId} from "../../Tools/NameId";
import {AutocompleteHelper} from "../Reusable/AutocompleteHelper";
import {DevOpsConnectorModel} from "../../Models/DevOpsConnectorModel";
import {DevOpsClient} from "../../Controllers/DevOps/DevOpsClient";
import ValidationMgr from "../../Tools/ValidationMgr";
import {BaseComponent} from "../Reusable/BaseComponent";
import HelpIcon from "@mui/icons-material/Help";

interface IState {
    IsQueryLoading: boolean;
    IsOrganizationsLoading: boolean;
    IsProjectsLoading: boolean;
}

interface IProps {
    DevOpsConnector: DevOpsConnectorModel
}

export class DevOpsConnector extends BaseComponent<IProps, IState> {
    private _validationMgr: ValidationMgr = new ValidationMgr();
    private _queries: NameId<string>[] = [];
    private _organizations: string[] = [];
    private _projects: string[] = [];

    constructor(props: IProps) {
        super(props);

        this.state = {
            IsQueryLoading: false,
            IsOrganizationsLoading: false,
            IsProjectsLoading: false
        };
    }

    async componentDidMount() {
    }

    private WithRender = (action: () => void) => {
        action();
        this.forceUpdate();
    }

    private CanLoadQueries() : boolean {
        const connector = this.props.DevOpsConnector;
        return connector.IsConnected && !!connector.Pat && !!connector.Organization && !!connector.Project;
    }

    private async TryLoadDevOpsQueries() {
        if( !this.CanLoadQueries() ) return;

        this.setState({IsQueryLoading: true});

        try {
            const devOpsClient = new DevOpsClient(this.props.DevOpsConnector);
            this._queries = await devOpsClient.GetQueries();
        } finally {
            this.setState({IsQueryLoading: false});
        }
    }

    private CanLoadOrganizations() : boolean {
        const connector = this.props.DevOpsConnector;

        return connector.IsConnected && !!connector.Pat;
    }

    private async TryLoadDevOpsOrganizations() {
        if( !this.CanLoadOrganizations() ) return;

        this.setState({IsOrganizationsLoading: true});

        try {
            const devOpsClient = new DevOpsClient(this.props.DevOpsConnector);
            this._organizations = await devOpsClient.GetOrganizations();
        } finally {
            this.setState({IsOrganizationsLoading: false});
        }
    }

    private CanLoadProjects() : boolean {
        const connector = this.props.DevOpsConnector;
        return connector.IsConnected && !!connector.Pat && !!connector.Organization;
    }

    private async TryLoadDevOpsProjects() {
        if( !this.CanLoadProjects() ) return;

        this.setState({IsProjectsLoading: true});

        try {
            let connector = this.props.DevOpsConnector;
            const devOpsClient = new DevOpsClient(connector);
            this._projects = await devOpsClient.GetProjects(connector.Organization);
        } finally {
            this.setState({IsProjectsLoading: false});
        }
    }

    render() {
        console.log("DevOpsConnector.render");

        const connector = this.props.DevOpsConnector;
        this._validationMgr.StartValidation();

        return (
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel control={
                        <Checkbox
                            checked={!!connector.IsConnected}
                            onChange={async (event) => {
                                this.WithRender( () => connector.IsConnected = event.target.checked);
                                await this.TryLoadDevOpsOrganizations();
                                await this.TryLoadDevOpsProjects();
                                await this.TryLoadDevOpsQueries();
                            }}/>
                    } label="Display Azure DevOps Work Items on the-Board"
                    />
                    {
                        <TextField
                            required={true}
                            {...this._validationMgr.Validate(connector.Pat, Validation.Required())}
                            InputLabelProps={{style: {zIndex: 10, cursor: "text"}}}
                            label={
                                <>
                                    {<span style={{verticalAlign: "text-top"}}>DevOps PAT</span>}
                                    <Tooltip classes={{tooltip: "wide-tooltip"}} enterDelay={700} enterNextDelay={700}
                                             title={<>
                                                 <Typography color="inherit">DevOps Private Access Token (PAT)</Typography>
                                                 {"Allows the-Board to access Azure DevOps data (work items etc.)."}
                                                 <br/>
                                                 {"PAT is created using DevOps web UI"}
                                                 <br/>
                                                 <br/>
                                                 <img alt={"pat-help"} src={DevOpsPatHelp}/>
                                             </>}>
                                        <HelpIcon style={{float: "right", marginLeft: 12, zIndex: 10, cursor: "help"}}/>
                                    </Tooltip>
                                </>}
                            placeholder={"DevOps PAT"}
                            {...this.BindObject(connector, "Pat", "string", async () => {
                                await this.TryLoadDevOpsOrganizations();
                                await this.TryLoadDevOpsQueries();
                            })}
                        />
                    }
                    {
                        AutocompleteHelper.RenderAutocomplete("Organization", "DevOps organization name", !this.CanLoadOrganizations(), this._organizations, this.state.IsOrganizationsLoading,
                            connector.Organization, async (event, newValue) => {
                                this.WithRender( () => connector.Organization = newValue);
                                await this.TryLoadDevOpsProjects();
                                await this.TryLoadDevOpsQueries();
                            })
                    }
                    {
                        AutocompleteHelper.RenderAutocomplete("Project", "DevOps project name", !this.CanLoadProjects(), this._projects, this.state.IsProjectsLoading,
                            connector.Project, async (event, newValue) => {
                                this.WithRender( () => connector.Project = newValue);
                                await this.TryLoadDevOpsQueries();
                            })
                    }
                    {
                        AutocompleteHelper.RenderAutocomplete("Query",
                            <>DevOps query to be used when loading Work Items on the-Board.<br/>You can configure query in your Azure DevOps portal (Boards -&gt; Queries)</>,
                            !this.CanLoadQueries(), this._queries, this.state.IsQueryLoading,
                            connector.Query, async (event, newValue) => {
                                this.WithRender( () => connector.Query = newValue);
                            }, (option) => option.Name || "")
                    }
                </Grid>
            </Grid>
        )
    }
}

