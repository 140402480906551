import {Component} from "react";
import {InputProps as StandardInputProps} from "@mui/material";

export class BaseComponent<IProps, IState> extends Component<IProps, IState> {
    protected BindState(statePropName: keyof IState, onChange?: StandardInputProps['onChange'])
        : { value: any, onChange: StandardInputProps['onChange'] } {
        return {
            value: this.state[statePropName] || "",
            onChange: (event) => {
                const result = {};
                result[statePropName as string] = event.target.value;

                this.setState(result, () => {
                    if( onChange )
                        onChange(event);
                });

            }
        };
    }

    protected BindObject<T>(object: T, objectPropName: keyof T,
                            type?: "number" | "string",
                            onChange?: StandardInputProps['onChange'])
        : { value: any, onChange: StandardInputProps['onChange'] } {
        return {
            value: object[objectPropName] || "",
            onChange: (event) => {
                const result = {};
                const val = type === "number" ? (parseInt(event.target.value) || 0) : event.target.value;
                object[objectPropName as string] = val;
                this.forceUpdate();

                if( onChange )
                    onChange(event);
            }
        };
    }

    protected WithLabel = (label: string) => ({label, placeholder: label});
}
